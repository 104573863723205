var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      ref: "lineManagement",
      staticClass: "lineManagement",
    },
    [
      _vm.channelList.length
        ? [
            _c(
              "div",
              { ref: "top-box", staticClass: "top-box" },
              [
                _c("div", { ref: "ActionBar", staticClass: "ActionBar" }, [
                  _c("div", { staticClass: "actionBar-box" }, [
                    _c("div", { staticClass: "col-1" }, [_vm._v("起点站：")]),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.formData.startStationList,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "startStationList", $$v)
                              },
                              expression: "formData.startStationList",
                            },
                          },
                          _vm._l(_vm.startSelect, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.label },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "actionBar-box" }, [
                    _c("div", { staticClass: "col-1" }, [_vm._v("终点站：")]),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              clearable: "",
                              filterable: "",
                              "collapse-tags": "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.formData.endStationList,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "endStationList", $$v)
                              },
                              expression: "formData.endStationList",
                            },
                          },
                          _vm._l(_vm.endSelect, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.label },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "actionBar-box" }, [
                    _c("div", { staticClass: "col-1" }, [_vm._v("所属企业：")]),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("companySelect", {
                          attrs: {
                            clearable: true,
                            "company-tree": _vm.companyList,
                            valuename: _vm.valueAddName,
                            value: _vm.valueAdd,
                          },
                          on: { getValue: _vm.getAddGroupId },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "actionBar-box" }, [
                    _c("div", { staticClass: "col-1" }, [
                      _vm._v("所属主线路："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              filterable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.formData.mainLineCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "mainLineCode", $$v)
                              },
                              expression: "formData.mainLineCode",
                            },
                          },
                          _vm._l(_vm.mainLineList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.lineName,
                                value: item.lineCode,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "actionBar-box" }, [
                    _c("div", { staticClass: "col-1" }, [_vm._v("运营模式：")]),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.formData.operationMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "operationMode", $$v)
                              },
                              expression: "formData.operationMode",
                            },
                          },
                          _vm._l(_vm.operationModeSelect, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "actionBar-box" }, [
                    _c("div", { staticClass: "col-1" }, [_vm._v("是否启用：")]),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择" },
                            model: {
                              value: _vm.formData.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "status", $$v)
                              },
                              expression: "formData.status",
                            },
                          },
                          _vm._l(_vm.statusSelect, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "actionBar-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: () => {
                              this.formData.currentPage = 1
                              _vm.renderData()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm.listFind("新增线路")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.onAdd },
                            },
                            [_vm._v("新增线路")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("Table", {
                  ref: "elTabB",
                  staticClass: "defaultTable",
                  attrs: {
                    "table-data": _vm.tableData,
                    "title-name": _vm.titleName,
                    operation: true,
                    "operation-width": "195",
                    "table-height": _vm.tableHeight,
                    "highlight-current-row": true,
                    emptyText: _vm.emptyText,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                _vm.listFind("编辑线路")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          size: "small",
                                          sort: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onUpdate(scope.scopeRow)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      sort: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toConfigFun(scope.scopeRow)
                                      },
                                    },
                                  },
                                  [_vm._v("线路配置")]
                                ),
                                _vm.listFind("线路规则")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "text",
                                          size: "small",
                                          sort: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.lineRulesFun(
                                              scope.scopeRow
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("线路规则")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    4084029655
                  ),
                }),
                _c(
                  "div",
                  { ref: "pagination", staticClass: "pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "sizes,total, prev, pager, next, jumper",
                        total: _vm.total,
                        "page-size": _vm.formData.pageSize,
                        "page-sizes": [10, 30, 50, 100],
                        "current-page": _vm.formData.currentPage,
                      },
                      on: {
                        "current-change": _vm.onCurrentChange,
                        "size-change": _vm.onSizeChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.lineRulesShow
              ? _c("line-rules", {
                  ref: "lineRulesRef",
                  attrs: { lineRowData: _vm.toConfigData },
                  on: {
                    closeRulesShow: function ($event) {
                      _vm.lineRulesShow = false
                    },
                  },
                })
              : _vm._e(),
            _vm.toConfigShow
              ? _c(
                  "div",
                  { ref: "bottom-box", staticClass: "bottom-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "exit", on: { click: _vm.returnList } },
                      [_c("i", { staticClass: "iconfont its_ba_fanhui" })]
                    ),
                    _c("div", { ref: "dataInfo", staticClass: "dataInfo" }, [
                      _c("div", { staticClass: "dataInfo-box" }, [
                        _c("label", [_vm._v("线路名称：")]),
                        _c("label", [_vm._v(_vm._s(_vm.toConfigData.name))]),
                      ]),
                      _c("div", { staticClass: "dataInfo-box" }, [
                        _c("label", [_vm._v("所属企业：")]),
                        _c("label", [
                          _vm._v(_vm._s(_vm.toConfigData.companyName)),
                        ]),
                      ]),
                      _c("div", { staticClass: "dataInfo-box" }, [
                        _c("label", [_vm._v("所属渠道：")]),
                        _c("label", [
                          _vm._v(_vm._s(_vm.toConfigData.channelName)),
                        ]),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.listFind("可售渠道"),
                              expression: "listFind('可售渠道')",
                            },
                          ],
                          staticClass: "dataInfo-box",
                        },
                        [
                          _c("label", [_vm._v("可售渠道：")]),
                          _c("label", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.toConfigData.vendibilityChannelName
                                    ? _vm.toConfigData.vendibilityChannelName.join(
                                        ","
                                      )
                                    : "无"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "dataInfo-box" }, [
                        _c("label", [_vm._v("购票类型：")]),
                        _c("label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.toConfigData.isRealName === 0
                                  ? "非实名"
                                  : "实名"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "dataInfo-box" }, [
                        _c("label", [_vm._v("估计用时：")]),
                        _c("label", [
                          _vm._v(_vm._s(_vm.toConfigData.drivingTime)),
                        ]),
                      ]),
                      _c("div", { staticClass: "dataInfo-box" }, [
                        _c("label", [_vm._v("核定价：")]),
                        _c("label", [
                          _vm._v(_vm._s(_vm.toConfigData.ticketPrice)),
                        ]),
                      ]),
                    ]),
                    _vm.listFind("委托售票-禁止开放")
                      ? _c(
                          "div",
                          {
                            ref: "entrustContent",
                            staticClass: "entrust-content",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "entrust-head" },
                              [
                                _c("div", { staticClass: "entrust-title" }, [
                                  _vm._v("委托售票"),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      sort: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAddEntrustPop()
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                ),
                              ],
                              1
                            ),
                            _c("Table", {
                              ref: "entrustTable",
                              attrs: {
                                "table-data": _vm.tableData0,
                                "title-name": _vm.titleName0,
                                operation: true,
                                "table-height": "150px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                  sort: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onAddEntrustPop(
                                                      scope.scopeRow
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                  sort:
                                                    scope.scopeRow.status === 0
                                                      ? "danger"
                                                      : "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.entrustupdatePop(
                                                      scope.scopeRow
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.scopeRow.status === 0
                                                      ? "暂停委托"
                                                      : "启用委托"
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm.listFind("删除委托售票不开放")
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                      sort: "danger",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.entrustdeletePop(
                                                          scope.scopeRow
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3079139041
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-tabs",
                      {
                        ref: "elTab",
                        style: _vm.tabHeight,
                        attrs: { type: "border-card" },
                      },
                      [
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "计划班次" } },
                          [
                            _vm.listFind("新增车次")
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "14px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.onAdd1 },
                                  },
                                  [_vm._v("新增排班计划")]
                                )
                              : _vm._e(),
                            _c("Table", {
                              ref: "tableRef1",
                              attrs: {
                                "table-data": _vm.tableData2,
                                "title-name": _vm.titleName2,
                                "col-class-type": true,
                                operation: true,
                                "operation-width": "220",
                                "table-height": _vm.bottomTableHeight,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            (
                                              scope.scopeRow.status === 0
                                                ? _vm.listFind("禁用车次")
                                                  ? true
                                                  : false
                                                : scope.scopeRow.status === 1
                                                ? _vm.listFind("启用车次")
                                                  ? true
                                                  : false
                                                : false
                                            )
                                              ? [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        sort:
                                                          scope.scopeRow
                                                            .status === 0
                                                            ? "danger"
                                                            : "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onDisable1(
                                                            scope.scopeRow
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.scopeRow
                                                            .status === 0
                                                            ? "停班"
                                                            : "启用"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.listFind("删除车次")
                                              ? [
                                                  scope.scopeRow.status === 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                            sort: "danger",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDelete1(
                                                                scope.scopeRow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                            [
                                              _c(
                                                "el-dropdown",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "el-dropdown-link",
                                                    },
                                                    [
                                                      _vm._v(" 更多 "),
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-arrow-down el-icon--right",
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-dropdown-menu",
                                                    {
                                                      attrs: {
                                                        slot: "dropdown",
                                                      },
                                                      slot: "dropdown",
                                                    },
                                                    [
                                                      _vm.listFind("编辑车次")
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            [
                                                              scope.scopeRow
                                                                .status === 0
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "small",
                                                                        sort: "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onUpdate1(
                                                                              scope.scopeRow
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "编辑"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.listFind("开班属性")
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            [
                                                              scope.scopeRow
                                                                .type === 0
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        type: "text",
                                                                        size: "small",
                                                                        sort: "primary",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.shiftBtnFun(
                                                                              scope.scopeRow
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "开班属性"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.listFind("复制车次")
                                                        ? _c(
                                                            "el-dropdown-item",
                                                            [
                                                              _c(
                                                                "el-button",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    size: "small",
                                                                    sort: "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.copyTrainFun(
                                                                          scope.scopeRow
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "复制班次"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3391245240
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "上车点" } },
                          [
                            _vm.listFind("新增上车点")
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "14px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.onAdd2 },
                                  },
                                  [_vm._v("新增上车点")]
                                )
                              : _vm._e(),
                            _c("Table", {
                              attrs: {
                                "table-data": _vm.upData,
                                "title-name": _vm.uptitleName,
                                "col-class-type": true,
                                operation: true,
                                "operation-width": "160",
                                "table-height": _vm.bottomTableHeight,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _vm.listFind("编辑上车点")
                                              ? [
                                                  scope.scopeRow.status === 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                            sort: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onUpdate2(
                                                                scope.scopeRow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                            (
                                              scope.scopeRow.status === 0
                                                ? _vm.listFind("禁用上车点")
                                                  ? true
                                                  : false
                                                : scope.scopeRow.status === 1
                                                ? _vm.listFind("启用上车点")
                                                  ? true
                                                  : false
                                                : false
                                            )
                                              ? [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        sort:
                                                          scope.scopeRow
                                                            .status === 0
                                                            ? "danger"
                                                            : "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onDisable2(
                                                            scope.scopeRow
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.scopeRow
                                                            .status === 0
                                                            ? "禁用"
                                                            : "启用"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2570190646
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "下车点" } },
                          [
                            _vm.listFind("新增下车点")
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "14px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.onAdd3 },
                                  },
                                  [_vm._v("新增下车点")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "dragContent" },
                              [
                                _c("Table", {
                                  attrs: {
                                    "table-data": _vm.downData,
                                    "title-name": _vm.downtitleName,
                                    "col-class-type": true,
                                    operation: true,
                                    "operation-width": "160",
                                    "drag-onoff": true,
                                    "table-height": _vm.bottomTableHeight,
                                  },
                                  on: { dragFinsh: _vm.dragFinsh },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _vm.listFind("编辑下车点")
                                                  ? [
                                                      scope.scopeRow.status ===
                                                      0
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                size: "small",
                                                                sort: "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onUpdate3(
                                                                      scope.scopeRow
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("编辑")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : _vm._e(),
                                                (
                                                  scope.scopeRow.status === 0
                                                    ? _vm.listFind("禁用下车点")
                                                      ? true
                                                      : false
                                                    : scope.scopeRow.status ===
                                                      1
                                                    ? _vm.listFind("启用下车点")
                                                      ? true
                                                      : false
                                                    : false
                                                )
                                                  ? [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                            sort:
                                                              scope.scopeRow
                                                                .status === 0
                                                                ? "danger"
                                                                : "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDisable3(
                                                                scope.scopeRow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              scope.scopeRow
                                                                .status === 0
                                                                ? "禁用"
                                                                : "启用"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  : _vm._e(),
                                                _vm.listFind("删除下车点")
                                                  ? [
                                                      scope.scopeRow.status ===
                                                      0
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                                size: "small",
                                                                sort: "danger",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onDelete3(
                                                                      scope.scopeRow
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("删除")]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    35858383
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-tab-pane",
                          { attrs: { label: "途经点" } },
                          [
                            _vm.listFind("新增途径点")
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-bottom": "14px" },
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onAdd4("新增")
                                      },
                                    },
                                  },
                                  [_vm._v("新增途径点")]
                                )
                              : _vm._e(),
                            _c("Table", {
                              attrs: {
                                "table-data": _vm.passPointData,
                                "title-name": _vm.passPointtitleName,
                                "col-class-type": true,
                                operation: true,
                                "operation-width": "160",
                                "table-height": _vm.bottomTableHeight,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          [
                                            _vm.listFind("编辑途径点")
                                              ? [
                                                  scope.scopeRow
                                                    .lineStationStatus === 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                            sort: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onUpdate4(
                                                                scope.scopeRow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                            (
                                              scope.scopeRow
                                                .lineStationStatus === 0
                                                ? _vm.listFind("禁用途径点")
                                                  ? true
                                                  : false
                                                : scope.scopeRow
                                                    .lineStationStatus === 1
                                                ? _vm.listFind("启用途径点")
                                                  ? true
                                                  : false
                                                : false
                                            )
                                              ? [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small",
                                                        sort:
                                                          scope.scopeRow
                                                            .lineStationStatus ===
                                                          0
                                                            ? "danger"
                                                            : "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onDisable4(
                                                            scope.scopeRow
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            scope.scopeRow
                                                              .lineStationStatus ===
                                                              0
                                                              ? "禁用"
                                                              : "启用"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                            _vm.listFind("删除途径点")
                                              ? [
                                                  scope.scopeRow
                                                    .lineStationStatus === 0
                                                    ? _c(
                                                        "el-button",
                                                        {
                                                          attrs: {
                                                            type: "text",
                                                            size: "small",
                                                            sort: "danger",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDelete4(
                                                                scope.scopeRow
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                270483624
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.title,
                  visible: _vm.dialogVisible1,
                  "close-on-click-modal": false,
                  width: "440px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible1 = $event
                  },
                  close: _vm.onDialogClose1,
                },
              },
              [
                _c(
                  "div",
                  { staticStyle: { height: "500px", "overflow-y": "auto" } },
                  [
                    _c(
                      "div",
                      { staticClass: "body" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "addForm1",
                            staticClass: "demo-form dialog-form",
                            attrs: {
                              model: _vm.addForm1,
                              rules: _vm.rules,
                              "label-width": "100px",
                              "label-position": "left",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "班次类型：",
                                  prop: "shiftType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { disabled: _vm.title == "修改" },
                                    on: { input: _vm.radioChange },
                                    model: {
                                      value: _vm.addForm1.shiftType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addForm1, "shiftType", $$v)
                                      },
                                      expression: "addForm1.shiftType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("定班"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("流水班"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开始时间：",
                                  prop: "startDepartureTime",
                                },
                              },
                              [
                                _c("el-time-picker", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    placeholder: "选择发车开始时间",
                                  },
                                  on: { change: _vm.handleStartTime },
                                  model: {
                                    value: _vm.addForm1.startDepartureTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm1,
                                        "startDepartureTime",
                                        $$v
                                      )
                                    },
                                    expression: "addForm1.startDepartureTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "结束时间：",
                                  prop: "endDepartureTime",
                                },
                              },
                              [
                                _c("el-time-picker", {
                                  attrs: {
                                    format: "HH:mm",
                                    "value-format": "HH:mm",
                                    disabled: _vm.addForm1.shiftType === 0,
                                    placeholder: "选择发车结束时间",
                                  },
                                  model: {
                                    value: _vm.addForm1.endDepartureTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm1,
                                        "endDepartureTime",
                                        $$v
                                      )
                                    },
                                    expression: "addForm1.endDepartureTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "票位数量：",
                                  prop: "ticketNum",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入票位数量",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm1.ticketNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm1,
                                        "ticketNum",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm1.ticketNum",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.title == "修改" &&
                            _vm.tableData0 &&
                            _vm.tableData0.length &&
                            _vm.listFind("委托售票-禁止开放")
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "委托售票：", prop: "" } },
                                  [
                                    _vm._l(
                                      _vm.tableData0,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "row-style",
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: `${item.authorizeName}：`,
                                                  prop: item.systemNo,
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  staticClass: "inner-input",
                                                  attrs: {
                                                    disabled:
                                                      _vm.addForm1
                                                        .authorizeVehicleShiftIntercitys[
                                                        index
                                                      ].status == 1,
                                                    placeholder: "请输入",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.bluerInput(
                                                        $event,
                                                        _vm.addForm1.ticketNum
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.addForm1
                                                        .authorizeVehicleShiftIntercitys[
                                                        index
                                                      ].ticketNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.addForm1
                                                          .authorizeVehicleShiftIntercitys[
                                                          index
                                                        ],
                                                        "ticketNum",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                        addForm1.authorizeVehicleShiftIntercitys[index]\n                          .ticketNum\n                      ",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "车辆类型：", prop: "level" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择车辆类型",
                                    },
                                    model: {
                                      value: _vm.addForm1.level,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addForm1, "level", $$v)
                                      },
                                      expression: "addForm1.level",
                                    },
                                  },
                                  _vm._l(_vm.levelList, function (role) {
                                    return _c("el-option", {
                                      key: role.value,
                                      attrs: {
                                        label: role.label,
                                        value: role.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "班次状态：", prop: "status" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择班次状态",
                                    },
                                    model: {
                                      value: _vm.addForm1.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addForm1, "status", $$v)
                                      },
                                      expression: "addForm1.status",
                                    },
                                  },
                                  _vm._l(_vm.statusList, function (role) {
                                    return _c("el-option", {
                                      key: role.value,
                                      attrs: {
                                        label: role.label,
                                        value: role.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "开班属性：", prop: "type" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择车次类型",
                                    },
                                    model: {
                                      value: _vm.addForm1.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addForm1, "type", $$v)
                                      },
                                      expression: "addForm1.type",
                                    },
                                  },
                                  _vm._l(_vm.carTypeList, function (role) {
                                    return _c("el-option", {
                                      key: role.value,
                                      attrs: {
                                        label: role.label,
                                        value: role.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "在线选座：",
                                  prop: "onlineSeatStatus",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择是否启用" },
                                    on: { change: _vm.onlineSeatFun },
                                    model: {
                                      value: _vm.addForm1.onlineSeatStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addForm1,
                                          "onlineSeatStatus",
                                          $$v
                                        )
                                      },
                                      expression: "addForm1.onlineSeatStatus",
                                    },
                                  },
                                  _vm._l(_vm.seatStatusList, function (role) {
                                    return _c("el-option", {
                                      key: role.value,
                                      attrs: {
                                        label: role.label,
                                        value: role.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.seatPlanIdProp
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "座位布局：",
                                      prop: _vm.seatPlanIdProp,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: true,
                                          loading: _vm.selectLoading,
                                          disabled: _vm.seatPlanIdProp
                                            ? false
                                            : true,
                                          clearable: "",
                                          placeholder: "请选择座位布局",
                                        },
                                        on: { change: _vm.seatChangeFun },
                                        model: {
                                          value: _vm.addForm1.seatPlanId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addForm1,
                                              "seatPlanId",
                                              $$v
                                            )
                                          },
                                          expression: "addForm1.seatPlanId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.seatLayoutList,
                                        function (role) {
                                          return _c("el-option", {
                                            key: role.id,
                                            attrs: {
                                              label: role.seatName,
                                              value: role.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.seatPlanIdProp &&
                                              _vm.addForm1.seatPlanId,
                                            expression:
                                              "seatPlanIdProp && addForm1.seatPlanId",
                                          },
                                        ],
                                        staticClass: "seatPreview",
                                        on: { click: _vm.seatPreviewFun },
                                      },
                                      [_vm._v(" 预览 ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "优惠票价：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    onkeyup:
                                      "this.value = this.value.replace(/[^\\d|.]/g,'');",
                                    clearable: "",
                                    placeholder: "请填写优惠票价",
                                  },
                                  model: {
                                    value: _vm.addForm1.discountTicketPrice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm1,
                                        "discountTicketPrice",
                                        $$v
                                      )
                                    },
                                    expression: "addForm1.discountTicketPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "优惠开始日期：" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择开始日期",
                                  },
                                  on: { change: _vm.form1StartTime },
                                  model: {
                                    value: _vm.addForm1.discountStartTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm1,
                                        "discountStartTime",
                                        $$v
                                      )
                                    },
                                    expression: "addForm1.discountStartTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "优惠截止日期：" } },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择截止日期",
                                  },
                                  on: { change: _vm.form2StartTime },
                                  model: {
                                    value: _vm.addForm1.discountEndTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm1,
                                        "discountEndTime",
                                        $$v
                                      )
                                    },
                                    expression: "addForm1.discountEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "提示信息：" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    type: "textarea",
                                    maxlength: "100",
                                    rows: 4,
                                    placeholder:
                                      "显示在乘客端班次列表，仅该班次会显示。最多填写100字。",
                                  },
                                  model: {
                                    value: _vm.addForm1.wayPoint,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm1, "wayPoint", $$v)
                                    },
                                    expression: "addForm1.wayPoint",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default", size: "small" },
                        on: {
                          click: () => {
                            this.dialogVisible1 = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onSave1 },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.dialogVisible2
              ? _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.title,
                      visible: _vm.dialogVisible2,
                      "close-on-click-modal": false,
                      width: "1200px",
                      top: "5vh",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible2 = $event
                      },
                      close: _vm.onDialogClose2,
                    },
                  },
                  [
                    _c("div", { staticClass: "mapBody" }, [
                      _c(
                        "div",
                        { staticClass: "topForm" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "addForm2",
                                  attrs: {
                                    model: _vm.addForm2,
                                    rules: _vm.rules,
                                    inline: true,
                                    "label-width": "100px",
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "位置类型：",
                                            prop: "areaMode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择位置类型",
                                                disabled: _vm.title == "修改",
                                              },
                                              on: {
                                                change: _vm.locationChangeFun,
                                              },
                                              model: {
                                                value: _vm.addForm2.areaMode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm2,
                                                    "areaMode",
                                                    $$v
                                                  )
                                                },
                                                expression: "addForm2.areaMode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.areaModeList,
                                              function (role) {
                                                return _c("el-option", {
                                                  key: role.value,
                                                  attrs: {
                                                    label: role.label,
                                                    value: role.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "位置名称：",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入位置名称",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.addForm2.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addForm2,
                                                  "name",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "addForm2.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 5 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "接驾时间：",
                                            prop: "pickUpTime",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "time" },
                                            [
                                              _c("el-input", {
                                                attrs: { type: "number" },
                                                model: {
                                                  value:
                                                    _vm.addForm2.pickUpTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.addForm2,
                                                      "pickUpTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "addForm2.pickUpTime",
                                                },
                                              }),
                                              _c("span", [_vm._v("分钟")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.listFind("上车点溢价")
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 5 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "溢价接驾(元)：",
                                                prop: "floatingPrice",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "time" },
                                                [
                                                  _c("el-input", {
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value:
                                                        _vm.addForm2
                                                          .floatingPrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addForm2,
                                                          "floatingPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addForm2.floatingPrice",
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("元")]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "上车点启用：",
                                            prop: "status",
                                          },
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66",
                                              "active-value": 0,
                                              "inactive-value": 1,
                                            },
                                            model: {
                                              value: _vm.addForm2.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addForm2,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "addForm2.status",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "bottomMap" },
                        [
                          _c("edit-map", {
                            ref: "editmap",
                            attrs: {
                              id: _vm.addForm2.id + `-` + _vm.addForm2.name,
                              title: _vm.title,
                              "location-type": _vm.locationType,
                              "area-content": _vm.addForm2.areaContent,
                              station: _vm.region[0],
                              "add-form2-copy": _vm.addForm2Copy,
                              "area-content-list":
                                _vm.locationType == 1 ? _vm.upData : [],
                            },
                            on: {
                              positionVariableFun: _vm.positionVariableFun,
                              areaContent: _vm.areaContentFUN,
                              changeAreaContent: _vm.changeAreaContent,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.locationType == 1
                        ? _c("div", { staticClass: "dot-tips" }, [
                            _c("header", [
                              _c("div", { staticClass: "dot" }),
                              _c("span", [_vm._v("定位点")]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _vm._v(
                                    "定位点建议不超过160个，当前已创建" +
                                      _vm._s(_vm.dotNum) +
                                      "个"
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "tip" }, [
                              _vm._v(
                                "定位点超过上限后，会导致高德地图识别失败"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default", size: "small" },
                            on: { click: _vm.onDialogClose2 },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              loading: _vm.editLoading,
                              disabled: _vm.dotNum >= 160,
                            },
                            on: { click: _vm.onSave2 },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.dialogVisible3
              ? _c(
                  "el-dialog",
                  {
                    attrs: {
                      title: _vm.title,
                      visible: _vm.dialogVisible3,
                      "close-on-click-modal": false,
                      width: "1200px",
                      top: "5vh",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible3 = $event
                      },
                      close: _vm.onDialogClose3,
                    },
                  },
                  [
                    _c("div", { staticClass: "mapBody" }, [
                      _c(
                        "div",
                        { staticClass: "topForm" },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "addForm3",
                                  attrs: {
                                    model: _vm.addForm3,
                                    rules: _vm.rules,
                                    inline: true,
                                    "label-width": "100px",
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "位置类型：",
                                            prop: "areaMode",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择位置类型",
                                                disabled: _vm.title == "修改",
                                              },
                                              on: {
                                                change: _vm.locationChangeFun,
                                              },
                                              model: {
                                                value: _vm.addForm3.areaMode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.addForm3,
                                                    "areaMode",
                                                    $$v
                                                  )
                                                },
                                                expression: "addForm3.areaMode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.areaModeList,
                                              function (role) {
                                                return _c("el-option", {
                                                  key: role.value,
                                                  attrs: {
                                                    label: role.label,
                                                    value: role.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "位置名称：",
                                            prop: "name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入位置名称",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.addForm3.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addForm3,
                                                  "name",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression: "addForm3.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.listFind("下车点溢价")
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "溢价送驾：",
                                                prop: "floatingPrice",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "time" },
                                                [
                                                  _c("el-input", {
                                                    attrs: { type: "number" },
                                                    model: {
                                                      value:
                                                        _vm.addForm3
                                                          .floatingPrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.addForm3,
                                                          "floatingPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "addForm3.floatingPrice",
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("元")]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "下车点启用：",
                                            prop: "status",
                                          },
                                        },
                                        [
                                          _c("el-switch", {
                                            attrs: {
                                              "active-color": "#13ce66",
                                              "active-value": 0,
                                              "inactive-value": 1,
                                            },
                                            model: {
                                              value: _vm.addForm3.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addForm3,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "addForm3.status",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "bottomMap" },
                        [
                          _c("edit-map", {
                            ref: "editmap",
                            attrs: {
                              id: _vm.addForm3.id + `-` + _vm.addForm3.name,
                              title: _vm.title,
                              "location-type": _vm.locationType,
                              "area-content": _vm.addForm3.areaContent,
                              station: _vm.region[1],
                              "add-form2-copy": _vm.addForm2Copy,
                              "area-content-list":
                                _vm.locationType == 1 ? _vm.downData : [],
                            },
                            on: {
                              areaContent: _vm.areaContentFUN,
                              positionVariableFun: _vm.positionVariableFun,
                              changeAreaContent: _vm.changeAreaContent,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.locationType == 1
                        ? _c("div", { staticClass: "dot-tips" }, [
                            _c("header", [
                              _c("div", { staticClass: "dot" }),
                              _c("span", [_vm._v("定位点")]),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _vm._v(
                                    "定位点建议不超过160个，当前已创建" +
                                      _vm._s(_vm.dotNum) +
                                      "个"
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "tip" }, [
                              _vm._v(
                                "定位点超过上限后，会导致高德地图识别失败"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default", size: "small" },
                            on: { click: _vm.onDialogClose3 },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              loading: _vm.editLoading,
                              disabled: _vm.dotNum >= 160,
                            },
                            on: { click: _vm.onSave3 },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "el-dialog",
              {
                attrs: {
                  "close-on-click-modal": false,
                  title: _vm.title,
                  visible: _vm.dialogVisible,
                  width: "1100px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogVisible = $event
                  },
                  close: _vm.onDialogClose,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "addFormbody" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addForm",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addForm,
                          rules: _vm.rules,
                          "label-width": "110px",
                          "label-position": "right",
                        },
                      },
                      [
                        _c("div", { staticClass: "classify" }, [
                          _vm._v("基本信息"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "classify-content" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "线路名称：", prop: "name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入线路名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属公司：",
                                  prop: "companyId",
                                },
                              },
                              [
                                _c("companySelect", {
                                  ref: "companySelect",
                                  attrs: {
                                    disabled: _vm.editDisabled,
                                    clearable: true,
                                    "company-tree": _vm.companyList,
                                    valuename: _vm.valueAddName,
                                    value: _vm.valueAdd,
                                  },
                                  on: {
                                    getName: _vm.getAddGroupName,
                                    getValue: _vm.getAddGroupId,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属主线路：",
                                  prop: "mainLineCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择所属主线路",
                                    },
                                    model: {
                                      value: _vm.addForm.mainLineCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "mainLineCode",
                                          $$v
                                        )
                                      },
                                      expression: "addForm.mainLineCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.mainLineList,
                                    function (role, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: role.lineName,
                                          value: role.lineCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "起点站：",
                                  prop: "startStation",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入起点站" },
                                  model: {
                                    value: _vm.addForm.startStation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "startStation",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm.startStation",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "终点站：",
                                  prop: "endStation",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入终点站" },
                                  model: {
                                    value: _vm.addForm.endStation,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "endStation",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm.endStation",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "参营公司：",
                                  prop: "participateOperateCompanyIds",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tips-out" },
                                  [
                                    _c("companySelect", {
                                      ref: "companySelectOpare",
                                      attrs: {
                                        clearable: true,
                                        multiple: true,
                                        "company-tree": _vm.companyList,
                                        valuenameMultiple:
                                          _vm.participateValueAddName,
                                        valueMultiple: _vm.participateValueAdd,
                                      },
                                      on: {
                                        getName: _vm.getAddGroupNameParticipate,
                                        getValue: _vm.getAddGroupIdParticipate,
                                      },
                                    }),
                                    _vm.addForm.participateOperateCompanyIds &&
                                    _vm.addForm.participateOperateCompanyIds
                                      .length &&
                                    _vm.editDisabled
                                      ? _c(
                                          "div",
                                          { staticClass: "company-tips" },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-warning",
                                            }),
                                            _vm._v(
                                              "取消参营公司，需先取消派车信息，再解除车辆和线路的绑定关系 "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "线路方向：",
                                  prop: "lineDirection",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择线路方向",
                                    },
                                    model: {
                                      value: _vm.addForm.lineDirection,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "lineDirection",
                                          $$v
                                        )
                                      },
                                      expression: "addForm.lineDirection",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "去程", value: 1 },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "返程", value: 2 },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.addForm.lineDirection == 2
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "去程线路：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          remote: "",
                                          "reserve-keyword": "",
                                          clearable: "",
                                          "remote-method":
                                            _vm.queryChildrenList,
                                          placeholder: "无去程线路可不填写",
                                        },
                                        on: { focus: _vm.queryChildrenList2 },
                                        model: {
                                          value: _vm.addForm.itineraryLineId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addForm,
                                              "itineraryLineId",
                                              $$v
                                            )
                                          },
                                          expression: "addForm.itineraryLineId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.childrenList,
                                        function (role, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              disabled: role.isBind,
                                              label: role.name,
                                              value: role.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.listFind("商品编码"),
                                    expression: "listFind('商品编码')",
                                  },
                                ],
                                attrs: { label: "商品编码：" },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入商品编码" },
                                  model: {
                                    value: _vm.addForm.wechatPayGoodsId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "wechatPayGoodsId",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm.wechatPayGoodsId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.addForm.lineDirection != 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { opacity: "0" },
                                    attrs: { label: "" },
                                  },
                                  [_c("el-input")],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "classify" }, [
                          _vm._v("车票信息"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "classify-content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "核定价：",
                                  prop: "ticketPrice",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入核定价",
                                    onkeyup:
                                      "this.value = this.value.replace(/[^\\d.]/g,'');",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.ticketPrice,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "ticketPrice",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm.ticketPrice",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "估计用时：",
                                  prop: "drivingTime",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入估计用时(分钟)",
                                    onkeyup:
                                      "this.value = this.value.replace(/[^\\d.]/g,'');",
                                  },
                                  model: {
                                    value: _vm.addForm.drivingTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "drivingTime",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addForm.drivingTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "购票类型：",
                                  prop: "isRealName",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tips-out" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          clearable: "",
                                          placeholder: "请选择购票类型",
                                        },
                                        model: {
                                          value: _vm.addForm.isRealName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addForm,
                                              "isRealName",
                                              $$v
                                            )
                                          },
                                          expression: "addForm.isRealName",
                                        },
                                      },
                                      _vm._l(
                                        _vm.isRealNameList,
                                        function (role) {
                                          return _c("el-option", {
                                            key: role.id,
                                            attrs: {
                                              label: role.title,
                                              value: role.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                    _vm.editDisabled
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "company-tips is-realName",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-warning",
                                            }),
                                            _vm._v("购票类型变更后次日生效 "),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "classify" }, [
                          _vm._v("服务信息"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "classify-content",
                            staticStyle: { "margin-bottom": "0" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单提醒号码：",
                                  prop: "phone",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addNumberBox" },
                                  [
                                    _vm._l(
                                      _vm.phoneNumberArray,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "11",
                                                clearable: "",
                                                placeholder:
                                                  "请输入订单提醒号码",
                                                onkeyup:
                                                  "this.value = this.value.replace(/[^\\d]/g,'');",
                                              },
                                              model: {
                                                value:
                                                  _vm.phoneNumberArray[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.phoneNumberArray,
                                                    index,
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "phoneNumberArray[index]",
                                              },
                                            }),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: index >= 1,
                                                    expression: "index >= 1",
                                                  },
                                                ],
                                                staticClass: "reduce",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.reducePhoneNumberFun(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              this.phoneNumberArray.length < 5,
                                            expression:
                                              "this.phoneNumberArray.length < 5",
                                          },
                                        ],
                                        staticClass: "add",
                                        on: { click: _vm.addPhoneNumberFun },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont its_zengjia",
                                        }),
                                        _vm._v("添加更多 "),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "classify-content",
                            staticStyle: { margin: "0" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客服电话：",
                                  prop: "customerPhone",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addNumberBox" },
                                  [
                                    _vm._l(
                                      _vm.customerServicePhone,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "11",
                                                clearable: "",
                                                placeholder:
                                                  "请输入客服电话号码",
                                                onkeyup:
                                                  "this.value = this.value.replace(/[^\\d]/g,'');",
                                              },
                                              model: {
                                                value:
                                                  _vm.customerServicePhone[
                                                    index
                                                  ],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.customerServicePhone,
                                                    index,
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "customerServicePhone[index]",
                                              },
                                            }),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: index >= 1,
                                                    expression: "index >= 1",
                                                  },
                                                ],
                                                staticClass: "reduce",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.reducecustomerFun(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              this.customerServicePhone.length <
                                              5,
                                            expression:
                                              "this.customerServicePhone.length < 5",
                                          },
                                        ],
                                        staticClass: "add",
                                        on: { click: _vm.addcustomerFun },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont its_zengjia",
                                        }),
                                        _vm._v("添加更多 "),
                                      ]
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "classify-content",
                            staticStyle: { margin: "0" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "线路标签：",
                                  prop: "lineLabels",
                                },
                              },
                              [
                                _vm._l(_vm.lineLabels, function (tag) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: tag,
                                      staticStyle: { "margin-right": "10px" },
                                      attrs: {
                                        closable: "",
                                        "disable-transitions": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.handleClose(tag)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(tag) + " ")]
                                  )
                                }),
                                _vm.inputVisible
                                  ? _c("el-input", {
                                      ref: "saveTagInput",
                                      staticClass: "input-new-tag",
                                      attrs: { size: "small" },
                                      on: {
                                        input: _vm.limitLength,
                                        blur: _vm.handleInputConfirm,
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.handleInputConfirm.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.inputValue,
                                        callback: function ($$v) {
                                          _vm.inputValue = $$v
                                        },
                                        expression: "inputValue",
                                      },
                                    })
                                  : !_vm.inputVisible &&
                                    _vm.lineLabels.length <= 1
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "button-new-tag",
                                        attrs: { size: "small" },
                                        on: { click: _vm.showInput },
                                      },
                                      [_vm._v("+ 最多填写8个字")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "classify-content",
                            staticStyle: { "margin-top": "0" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "提示信息：",
                                  prop: "lineTipsInfo",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "textareaBox" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        maxlength: "100",
                                        rows: 6,
                                        placeholder:
                                          "显示在乘客端班次列表，该线路所有班次均会显示。",
                                      },
                                      model: {
                                        value: _vm.addForm.lineTipsInfo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addForm,
                                            "lineTipsInfo",
                                            $$v
                                          )
                                        },
                                        expression: "addForm.lineTipsInfo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "classify-content",
                            staticStyle: { "margin-top": "0" },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "线路通知：", prop: "notice" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "addNumberBox textareaBox" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            type: "textarea",
                                            maxlength: "100",
                                            rows: 6,
                                            placeholder:
                                              "请输入通知内容，通知内容将展示在乘客端的班次列表页",
                                          },
                                          model: {
                                            value: _vm.addForm.notice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.addForm,
                                                "notice",
                                                $$v
                                              )
                                            },
                                            expression: "addForm.notice",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "company-tips is-realName",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-warning",
                                            }),
                                            _vm._v(
                                              "通知内容请勿手动输入大括号{ }，以免导致显示错误。"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "add",
                                        on: {
                                          click: _vm.addPhoneNumberLineFun,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont its_zengjia",
                                        }),
                                        _vm._v("插入联系电话 "),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "classify" }, [
                          _vm._v("售票渠道"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "classify-content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "所属渠道：",
                                  prop: "channelNo",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: _vm.editDisabled,
                                      placeholder: "请选择所属渠道",
                                    },
                                    on: { change: _vm.channelNoFun },
                                    model: {
                                      value: _vm.addForm.channelNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.addForm, "channelNo", $$v)
                                      },
                                      expression: "addForm.channelNo",
                                    },
                                  },
                                  _vm._l(_vm.channelList, function (role) {
                                    return _c("el-option", {
                                      key: role.channelNo,
                                      attrs: {
                                        label: role.channelName,
                                        value: role.channelNo,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.listFind("可售渠道"),
                                    expression: "listFind('可售渠道')",
                                  },
                                ],
                                attrs: {
                                  label: "可售渠道：",
                                  prop: "vendibilityChannelNo",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      multiple: "",
                                      "collapse-tags": true,
                                      placeholder: "请选择可售渠道",
                                    },
                                    model: {
                                      value: _vm.addForm.vendibilityChannelNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addForm,
                                          "vendibilityChannelNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addForm.vendibilityChannelNo",
                                    },
                                  },
                                  _vm._l(_vm.vendibilityList, function (role) {
                                    return _c("el-option", {
                                      key: role.channelNo,
                                      attrs: {
                                        label: role.channelName,
                                        value: role.channelNo,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { opacity: "0" },
                                attrs: { label: "" },
                              },
                              [_c("el-input")],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.listFind("微信二维码"),
                                expression: "listFind('微信二维码')",
                              },
                            ],
                            staticStyle: { display: "block" },
                            attrs: {
                              label: "微信二维码：",
                              prop: _vm.wxQrCode,
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "elUpload",
                                staticClass: "upload-demo",
                                attrs: {
                                  action: "",
                                  accept: ".jpg,.png",
                                  "list-type": "picture-card",
                                  "auto-upload": false,
                                  multiple: false,
                                  "on-change": _vm.imageSuccessFun,
                                  "on-remove": _vm.imageListRemove,
                                  "file-list": _vm.fileList,
                                  limit: 2,
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-plus" }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-upload__tip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [
                                    _vm._v(
                                      " 只能上传jpg/png文件，且不超过240kb "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "线路启用：" } },
                          [
                            _c("el-switch", {
                              attrs: {
                                "inactive-color": "#E3E3E3",
                                "active-color": "#336ffe",
                              },
                              model: {
                                value: _vm.addForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addForm, "status", $$v)
                                },
                                expression: "addForm.status",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.title == "新增"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "账号绑定：" } },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "inactive-color": "#E3E3E3",
                                    "active-color": "#336ffe",
                                  },
                                  model: {
                                    value: _vm.addForm.accountBinding,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addForm,
                                        "accountBinding",
                                        $$v
                                      )
                                    },
                                    expression: "addForm.accountBinding",
                                  },
                                }),
                                _c("p", [
                                  _vm._v(
                                    " 开启状态，线路所属公司及上级公司的账号均会绑定该线路。关闭状态，该线路不绑定账号 "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default", size: "small" },
                        on: {
                          click: () => {
                            _vm.seeShow = true
                            _vm.dialogVisible = false
                            _vm.fileList = []
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.seeShow,
                            expression: "seeShow",
                          },
                        ],
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.editLoading,
                        },
                        on: { click: _vm.onSave },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("seatSelectionDialog", { ref: "seatSelectionDialog" }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imgPopup,
                    expression: "imgPopup",
                  },
                ],
                staticClass: "imgPopup",
                on: { click: () => (_vm.imgPopup = false) },
              },
              [
                _c("img", {
                  attrs: { src: _vm.imgPopupImageUrl, alt: "" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                }),
              ]
            ),
            _vm.shiftPopupShow
              ? _c(
                  "charterPopup",
                  {
                    attrs: { width: 900, headerText: "开班属性" },
                    on: {
                      confirmFun: _vm.confirmFun,
                      closePopupFun: _vm.clearForm,
                    },
                  },
                  [
                    _c("div", { staticClass: "shift-attribute-pop" }, [
                      _c("div", { staticClass: "shift-title" }, [
                        _vm._v("开班设置"),
                      ]),
                      _c("div", { staticClass: "shift-box" }, [
                        _c(
                          "div",
                          { staticClass: "shift-content" },
                          [
                            _c("div", { staticClass: "shift-label" }, [
                              _vm._v("基准日期："),
                            ]),
                            _c("el-date-picker", {
                              staticClass: "shiftDatePicker",
                              attrs: { type: "date", placeholder: "选择日期" },
                              model: {
                                value: _vm.shiftDatePickerValue,
                                callback: function ($$v) {
                                  _vm.shiftDatePickerValue = $$v
                                },
                                expression: "shiftDatePickerValue",
                              },
                            }),
                            _c("span", { staticStyle: { color: "#ccc" } }, [
                              _vm._v("（开始执行日期）"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "shift-content" },
                          [
                            _c("div", { staticClass: "shift-label" }, [
                              _vm._v("开班属性："),
                            ]),
                            _c(
                              "el-select",
                              {
                                staticClass: "shift-select",
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.shiftCourseFun },
                                model: {
                                  value: _vm.shiftListValue,
                                  callback: function ($$v) {
                                    _vm.shiftListValue = $$v
                                  },
                                  expression: "shiftListValue",
                                },
                              },
                              _vm._l(_vm.shiftList, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.label, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm.shiftListValue
                              ? _c(
                                  "div",
                                  { staticClass: "shift-course-text" },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont its_cuowu",
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.shiftCourseLabel) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.shiftStageShow
                          ? _c("div", { staticClass: "shift-content" }, [
                              _c("div", { staticClass: "shift-label" }, [
                                _vm._v(
                                  " 阶段" +
                                    _vm._s(
                                      _vm.shiftListValue == 2 ? "开" : "停"
                                    ) +
                                    "班时间段： "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "shift-stage" },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "shift-stage-date",
                                    attrs: {
                                      type: "daterange",
                                      "picker-options": _vm.pickerOptions,
                                      "range-separator": "至",
                                      "start-placeholder": "开始日期",
                                      "end-placeholder": "结束日期",
                                    },
                                    model: {
                                      value: _vm.shiftAddDateValue,
                                      callback: function ($$v) {
                                        _vm.shiftAddDateValue = $$v
                                      },
                                      expression: "shiftAddDateValue",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { type: "primary", size: "small" },
                                      on: { click: _vm.shiftAddDateFun },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                  _c("Table", {
                                    staticClass: "shiftTable",
                                    attrs: {
                                      "table-data": _vm.shiftTableData,
                                      "title-name": _vm.shiftTableTitleName,
                                      operation: true,
                                      "table-height":
                                        _vm.shiftTableData.length > 0
                                          ? 200
                                          : 100,
                                      "operation-width": "150",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                    sort: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.shiftDeleteFun(
                                                        scope.scopeRow
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      865090871
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "shift-title",
                          staticStyle: { "margin-top": "30px" },
                        },
                        [_vm._v("排班设置")]
                      ),
                      _c("div", { staticClass: "shift-box" }, [
                        _c(
                          "div",
                          { staticClass: "shiftA" },
                          [
                            _c("div", { staticClass: "shift-label" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.shiftListValue == 3 ||
                                      _vm.shiftListValue == 4
                                      ? "停"
                                      : "开"
                                  ) +
                                  "班属性： "
                              ),
                            ]),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                model: {
                                  value: _vm.shiftAradio,
                                  callback: function ($$v) {
                                    _vm.shiftAradio = $$v
                                  },
                                  expression: "shiftAradio",
                                },
                              },
                              [_vm._v("周期性")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2" },
                                model: {
                                  value: _vm.shiftAradio,
                                  callback: function ($$v) {
                                    _vm.shiftAradio = $$v
                                  },
                                  expression: "shiftAradio",
                                },
                              },
                              [_vm._v("按日历")]
                            ),
                            _c(
                              "div",
                              { staticClass: "shiftA-box" },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "16px" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "shift-select",
                                        attrs: { placeholder: "请选择" },
                                        on: {
                                          change: _vm.shiftAttributeChange,
                                        },
                                        model: {
                                          value: _vm.attributeValue,
                                          callback: function ($$v) {
                                            _vm.attributeValue = $$v
                                          },
                                          expression: "attributeValue",
                                        },
                                      },
                                      _vm._l(
                                        _vm.attributeList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.label,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.shiftCheckedShow
                                  ? _c(
                                      "el-checkbox-group",
                                      {
                                        model: {
                                          value: _vm.shiftAttributeCheckedValue,
                                          callback: function ($$v) {
                                            _vm.shiftAttributeCheckedValue = $$v
                                          },
                                          expression:
                                            "shiftAttributeCheckedValue",
                                        },
                                      },
                                      _vm._l(
                                        _vm.attributeChecked,
                                        function (item) {
                                          return _c("el-checkbox", {
                                            key: item,
                                            attrs: { label: item },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm.shiftCalendarShow
                                  ? _c(
                                      "div",
                                      { staticClass: "shift-calendar" },
                                      _vm._l(_vm.calendarNum, function (item) {
                                        return _c(
                                          "div",
                                          {
                                            key: item,
                                            class: _vm.calendarClassFun(item),
                                            on: {
                                              click: function ($event) {
                                                return _vm.calendarFun(item)
                                              },
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item) + " ")]
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _c(
              "el-dialog",
              {
                attrs: {
                  "close-on-click-modal": false,
                  title: "复制班次",
                  visible: _vm.copyPopup,
                  width: "440px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.copyPopup = $event
                  },
                  close: _vm.copyPopupClose,
                },
              },
              [
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "body" },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "copyForm",
                          staticClass: "demo-form dialog-form",
                          attrs: {
                            model: _vm.copyForm,
                            rules: _vm.rulesCopy,
                            "label-width": "100px",
                            "label-position": "left",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开始时间：",
                                prop: "startDepartureTime",
                              },
                            },
                            [
                              _c("el-time-picker", {
                                attrs: {
                                  format: "HH:mm",
                                  "value-format": "HH:mm",
                                  placeholder: "选择开始时间",
                                },
                                on: { change: _vm.handleStartCopyTime },
                                model: {
                                  value: _vm.copyForm.startDepartureTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.copyForm,
                                      "startDepartureTime",
                                      $$v
                                    )
                                  },
                                  expression: "copyForm.startDepartureTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "结束时间：",
                                prop: "endDepartureTime",
                              },
                            },
                            [
                              _c("el-time-picker", {
                                attrs: {
                                  format: "HH:mm",
                                  disabled: _vm.copyShiftType === 0,
                                  "value-format": "HH:mm",
                                  placeholder: "选择结束时间",
                                },
                                model: {
                                  value: _vm.copyForm.endDepartureTime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.copyForm,
                                      "endDepartureTime",
                                      $$v
                                    )
                                  },
                                  expression: "copyForm.endDepartureTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.copyisShow
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "开班属性：" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      disabled: true,
                                      rows: 4,
                                    },
                                    model: {
                                      value: _vm.copyTextarea,
                                      callback: function ($$v) {
                                        _vm.copyTextarea = $$v
                                      },
                                      expression: "copyTextarea",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "default", size: "small" },
                        on: {
                          click: () => {
                            this.copyPopup = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.copyCommit },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm.dialogVisiblePassPoint
              ? _c(
                  "el-dialog",
                  {
                    attrs: {
                      "close-on-click-modal": false,
                      title: _vm.passPointTitle,
                      visible: _vm.dialogVisiblePassPoint,
                      width: "520px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisiblePassPoint = $event
                      },
                      close: _vm.onDialogClosePassPoint,
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addFormPassPoint",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addFormPassPoint,
                          rules: _vm.rulesPassPoint,
                          "label-width": "138px",
                          "label-position": "left",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "途经点名称：", prop: "stationId" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择途经点",
                                  disabled: _vm.isDisabled,
                                },
                                on: { change: _vm.passStationChange },
                                model: {
                                  value: _vm.addFormPassPoint.stationId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addFormPassPoint,
                                      "stationId",
                                      $$v
                                    )
                                  },
                                  expression: "addFormPassPoint.stationId",
                                },
                              },
                              _vm._l(_vm.stationList, function (element) {
                                return _c("el-option", {
                                  key: element.id,
                                  attrs: {
                                    label: element.name,
                                    value: element.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "显示名称：",
                              prop: "stationAlias",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入显示名称",
                                clearable: "",
                              },
                              model: {
                                value: _vm.addFormPassPoint.stationAlias,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addFormPassPoint,
                                    "stationAlias",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addFormPassPoint.stationAlias",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "途经点类型：",
                              prop: "stationType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择途经点类型",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.addFormPassPoint.stationType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addFormPassPoint,
                                      "stationType",
                                      $$v
                                    )
                                  },
                                  expression: "addFormPassPoint.stationType",
                                },
                              },
                              _vm._l(_vm.areaPassModeList, function (role) {
                                return _c("el-option", {
                                  key: role.value,
                                  attrs: {
                                    label: role.label,
                                    value: role.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "距出发地估计用时：",
                              prop: "timeEstimation",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入用时(分钟)",
                                clearable: "",
                              },
                              model: {
                                value: _vm.addFormPassPoint.timeEstimation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addFormPassPoint,
                                    "timeEstimation",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addFormPassPoint.timeEstimation",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "距出发地票价：", prop: "price" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入票价(元)",
                                clearable: "",
                              },
                              model: {
                                value: _vm.addFormPassPoint.price,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addFormPassPoint,
                                    "price",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addFormPassPoint.price",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default", size: "small" },
                            on: { click: _vm.onDialogClosePassPoint },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.onSavePassPoint },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.dialogVisibleEntrustPop
              ? _c(
                  "el-dialog",
                  {
                    attrs: {
                      "close-on-click-modal": false,
                      title: "委托售票",
                      visible: _vm.dialogVisibleEntrustPop,
                      width: "520px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleEntrustPop = $event
                      },
                      close: _vm.onDialogCloseEntrust,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "body" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "addEntrustForm",
                            staticClass: "demo-form dialog-form",
                            attrs: {
                              model: _vm.addEntrustForm,
                              rules: _vm.rulesEntrust,
                              "label-width": "138px",
                              "label-position": "right",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "委托售票平台：",
                                  prop: "systemNo",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    model: {
                                      value: _vm.addEntrustForm.systemNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addEntrustForm,
                                          "systemNo",
                                          $$v
                                        )
                                      },
                                      expression: "addEntrustForm.systemNo",
                                    },
                                  },
                                  _vm._l(_vm.systemNoList, function (item) {
                                    return _c("el-option", {
                                      key: item.no,
                                      attrs: {
                                        label: item.desc,
                                        value: item.no,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "班次委托售票比例：",
                                  prop: "authorizeRatio",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "请输入",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addEntrustForm.authorizeRatio,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addEntrustForm,
                                        "authorizeRatio",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "addEntrustForm.authorizeRatio",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default", size: "small" },
                            on: { click: _vm.onDialogCloseEntrust },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "small",
                              loading: _vm.editLoading,
                            },
                            on: { click: _vm.entrustSave },
                          },
                          [_vm._v(" 确认 ")]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm.dialogVisibleInputPhone
              ? _c(
                  "el-dialog",
                  {
                    attrs: {
                      "close-on-click-modal": false,
                      title: "插入联系电话",
                      visible: _vm.dialogVisibleInputPhone,
                      width: "420px",
                    },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleInputPhone = $event
                      },
                      close: _vm.onDialogCloseInputPhone,
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addFormInputPhone",
                        staticClass: "demo-form dialog-form",
                        attrs: {
                          model: _vm.addFormInputPhone,
                          rules: _vm.rulesInputPhone,
                          "label-width": "96px",
                          "label-position": "left",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "联系电话：",
                              prop: "customerPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "11",
                                clearable: "",
                                placeholder: "请输入联系号码",
                                onkeyup:
                                  "this.value = this.value.replace(/[^\\d]/g,'');",
                              },
                              model: {
                                value: _vm.addFormInputPhone.customerPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addFormInputPhone,
                                    "customerPhone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addFormInputPhone.customerPhone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "default", size: "small" },
                            on: { click: _vm.onDialogCloseInputPhone },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.onSaveInputPhone },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm.emptyFlag
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "font-size": "18px",
                "text-align": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/empty.png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v("暂无权限，请联系管理员")]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div ref="lineManagement" v-loading="pageLoading" class="lineManagement">
    <template v-if="channelList.length">
      <!-- 上半部分 -->
      <div ref="top-box" class="top-box">
        <!-- 操作栏 -->
        <div class="ActionBar" ref="ActionBar">
          <div class="actionBar-box">
            <div class="col-1">起点站：</div>
            <div class="col-2">
              <el-select
                v-model="formData.startStationList"
                multiple
                collapse-tags
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in startSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="actionBar-box">
            <div class="col-1">终点站：</div>
            <div class="col-2">
              <el-select
                v-model="formData.endStationList"
                multiple
                clearable
                filterable
                collapse-tags
                placeholder="请选择"
              >
                <el-option
                  v-for="item in endSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="actionBar-box">
            <div class="col-1">所属企业：</div>
            <div class="col-2">
              <companySelect
                :clearable="true"
                :company-tree="companyList"
                :valuename="valueAddName"
                :value="valueAdd"
                @getValue="getAddGroupId"
              ></companySelect>
            </div>
          </div>
          <div class="actionBar-box">
            <div class="col-1">所属主线路：</div>
            <div class="col-2">
              <el-select
                v-model="formData.mainLineCode"
                clearable
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in mainLineList"
                  :key="index"
                  :label="item.lineName"
                  :value="item.lineCode"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="actionBar-box">
            <div class="col-1">运营模式：</div>
            <div class="col-2">
              <el-select
                v-model="formData.operationMode"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in operationModeSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="actionBar-box">
            <div class="col-1">是否启用：</div>
            <div class="col-2">
              <el-select
                v-model="formData.status"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in statusSelect"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="actionBar-box">
            <el-button
              type="primary"
              size="small"
              @click="
                () => {
                  this.formData.currentPage = 1
                  renderData()
                }
              "
              >查询</el-button
            >
            <el-button
              v-if="listFind('新增线路')"
              type="primary"
              size="small"
              @click="onAdd"
              >新增线路</el-button
            >
          </div>
        </div>
        <!-- 班线表格 -->
        <Table
          ref="elTabB"
          class="defaultTable"
          :table-data="tableData"
          :title-name="titleName"
          :operation="true"
          operation-width="195"
          :table-height="tableHeight"
          :highlight-current-row="true"
          :emptyText="emptyText"
        >
          <template slot-scope="scope">
            <div>
              <!-- <el-button
                type="text"
                size="small"
                sort="primary"
                @click="onUpdate(scope.scopeRow, 'see')"
                >查看</el-button
              > -->
              <el-button
                v-if="listFind('编辑线路')"
                type="text"
                size="small"
                sort="primary"
                @click="onUpdate(scope.scopeRow)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="toConfigFun(scope.scopeRow)"
                >线路配置</el-button
              >
              <el-button
                v-if="listFind('线路规则')"
                type="text"
                size="small"
                sort="primary"
                @click="lineRulesFun(scope.scopeRow)"
                >线路规则</el-button
              >
              <!-- <el-button type="text" size="small" sort="primary" @click="onDelete(scope.scopeRow)">删除</el-button> -->
            </div>
          </template>
        </Table>
        <div ref="pagination" class="pagination">
          <el-pagination
            background
            layout="sizes,total, prev, pager, next, jumper"
            :total="total"
            :page-size="formData.pageSize"
            :page-sizes="[10, 30, 50, 100]"
            :current-page="formData.currentPage"
            @current-change="onCurrentChange"
            @size-change="onSizeChange"
          ></el-pagination>
        </div>
      </div>
      <!-- 点击线路规则 -->
      <line-rules
        v-if="lineRulesShow"
        ref="lineRulesRef"
        :lineRowData="toConfigData"
        @closeRulesShow="lineRulesShow = false"
      ></line-rules>
      <!-- 点击配置弹出部分 -->
      <div v-if="toConfigShow" ref="bottom-box" class="bottom-box">
        <div class="exit" @click="returnList">
          <i class="iconfont its_ba_fanhui"></i>
        </div>
        <!-- 关键信息展示 -->
        <div class="dataInfo" ref="dataInfo">
          <div class="dataInfo-box">
            <label>线路名称：</label>
            <label>{{ toConfigData.name }}</label>
          </div>
          <div class="dataInfo-box">
            <label>所属企业：</label>
            <label>{{ toConfigData.companyName }}</label>
          </div>
          <div class="dataInfo-box">
            <label>所属渠道：</label>
            <label>{{ toConfigData.channelName }}</label>
          </div>
          <div v-show="listFind('可售渠道')" class="dataInfo-box">
            <label>可售渠道：</label>
            <label>
              {{
                toConfigData.vendibilityChannelName
                  ? toConfigData.vendibilityChannelName.join(',')
                  : '无'
              }}
            </label>
          </div>
          <div class="dataInfo-box">
            <label>购票类型：</label>
            <label>
              {{ toConfigData.isRealName === 0 ? '非实名' : '实名' }}
            </label>
          </div>
          <div class="dataInfo-box">
            <label>估计用时：</label>
            <label>{{ toConfigData.drivingTime }}</label>
          </div>
          <div class="dataInfo-box">
            <label>核定价：</label>
            <label>{{ toConfigData.ticketPrice }}</label>
          </div>
        </div>
        <!-- 委托售票 -->
        <div v-if="listFind('委托售票-禁止开放')" class="entrust-content" ref="entrustContent">
          <div class="entrust-head">
            <div class="entrust-title">委托售票</div>
            <el-button
              type="text"
              size="small"
              sort="primary"
              @click="onAddEntrustPop()"
              >添加</el-button
            >
          </div>
          <Table
            ref="entrustTable"
            :table-data="tableData0"
            :title-name="titleName0"
            :operation="true"
            table-height="150px"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  type="text"
                  size="small"
                  sort="primary"
                  @click="onAddEntrustPop(scope.scopeRow)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  :sort="scope.scopeRow.status === 0 ? 'danger' : 'primary'"
                  @click="entrustupdatePop(scope.scopeRow)"
                  >{{
                    scope.scopeRow.status === 0 ? '暂停委托' : '启用委托'
                  }}</el-button
                >
                <el-button
                  type="text"
                  v-if="listFind('删除委托售票不开放')"
                  size="small"
                  sort="danger"
                  @click="entrustdeletePop(scope.scopeRow)"
                  >删除</el-button
                >
              </div>
            </template>
          </Table>
        </div>
        <el-tabs
          ref="elTab"
          type="border-card"
          :style="tabHeight"
        >
          <el-tab-pane label="计划班次">
            <el-button
              v-if="listFind('新增车次')"
              type="primary"
              size="small"
              style="margin-bottom: 14px"
              @click="onAdd1"
              >新增排班计划</el-button
            >
            <Table
              :table-data="tableData2"
              :title-name="titleName2"
              :col-class-type="true"
              :operation="true"
              operation-width="220"              
              :table-height="bottomTableHeight"
              ref="tableRef1"
            >
              <template slot-scope="scope">
                <div>
                  <template
                    v-if="
                      scope.scopeRow.status === 0
                        ? listFind('禁用车次')
                          ? true
                          : false
                        : scope.scopeRow.status === 1
                        ? listFind('启用车次')
                          ? true
                          : false
                        : false
                    "
                  >
                    <el-button
                      type="text"
                      size="small"
                      :sort="scope.scopeRow.status === 0 ? 'danger' : 'primary'"
                      @click="onDisable1(scope.scopeRow)"
                      >{{
                        scope.scopeRow.status === 0 ? '停班' : '启用'
                      }}</el-button
                    >
                  </template>
                  <template v-if="listFind('删除车次')">
                    <el-button
                      v-if="scope.scopeRow.status === 0"
                      type="text"
                      size="small"
                      sort="danger"
                      @click="onDelete1(scope.scopeRow)"
                      >删除</el-button
                    >
                  </template>
                  <template>
                    <el-dropdown style="margin-left: 10px">
                      <span class="el-dropdown-link">
                        更多
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="listFind('编辑车次')">
                          <el-button
                            v-if="scope.scopeRow.status === 0"
                            type="text"
                            size="small"
                            sort="primary"
                            @click="onUpdate1(scope.scopeRow)"
                            >编辑</el-button
                          >
                        </el-dropdown-item>
                        <el-dropdown-item v-if="listFind('开班属性')">
                          <el-button
                            v-if="scope.scopeRow.type === 0"
                            type="text"
                            size="small"
                            sort="primary"
                            @click="shiftBtnFun(scope.scopeRow)"
                            >开班属性</el-button
                          >
                        </el-dropdown-item>
                        <el-dropdown-item v-if="listFind('复制车次')">
                          <el-button
                            type="text"
                            size="small"
                            sort="primary"
                            @click="copyTrainFun(scope.scopeRow)"
                            >复制班次</el-button
                          >
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </div>
              </template>
            </Table>
          </el-tab-pane>
          <el-tab-pane label="上车点">
            <el-button
              v-if="listFind('新增上车点')"
              style="margin-bottom: 14px"
              type="primary"
              size="small"
              @click="onAdd2"
              >新增上车点</el-button
            >
            <Table
              :table-data="upData"
              :title-name="uptitleName"
              :col-class-type="true"
              :operation="true"
              operation-width="160"
              :table-height="bottomTableHeight"
            >
              <template slot-scope="scope">
                <div>
                  <template v-if="listFind('编辑上车点')">
                    <el-button
                      v-if="scope.scopeRow.status === 0"
                      type="text"
                      size="small"
                      sort="primary"
                      @click="onUpdate2(scope.scopeRow)"
                      >编辑</el-button
                    >
                  </template>
                  <template
                    v-if="
                      scope.scopeRow.status === 0
                        ? listFind('禁用上车点')
                          ? true
                          : false
                        : scope.scopeRow.status === 1
                        ? listFind('启用上车点')
                          ? true
                          : false
                        : false
                    "
                  >
                    <el-button
                      type="text"
                      size="small"
                      :sort="scope.scopeRow.status === 0 ? 'danger' : 'primary'"
                      @click="onDisable2(scope.scopeRow)"
                      >{{
                        scope.scopeRow.status === 0 ? '禁用' : '启用'
                      }}</el-button
                    >
                  </template>
                  <!-- <template v-if="listFind('删除上车点')">
                  <el-button
                    v-if="scope.scopeRow.status === 0"
                    type="text"
                    size="small"
                    sort="danger"
                    @click="onDelete2(scope.scopeRow)"
                  >删除</el-button>
                  </template>-->
                </div>
              </template>
            </Table>
          </el-tab-pane>
          <el-tab-pane label="下车点">
            <el-button
              v-if="listFind('新增下车点')"
              style="margin-bottom: 14px"
              type="primary"
              size="small"
              @click="onAdd3"
              >新增下车点</el-button
            >
            <div class="dragContent">
              <Table
              :table-data="downData"
              :title-name="downtitleName"
              :col-class-type="true"
              :operation="true"
              operation-width="160"
              :drag-onoff="true"
              :table-height="bottomTableHeight"
              @dragFinsh="dragFinsh"
            >
              <template slot-scope="scope">
                <div>
                  <template v-if="listFind('编辑下车点')">
                    <el-button
                      v-if="scope.scopeRow.status === 0"
                      type="text"
                      size="small"
                      sort="primary"
                      @click="onUpdate3(scope.scopeRow)"
                      >编辑</el-button
                    >
                  </template>
                  <template
                    v-if="
                      scope.scopeRow.status === 0
                        ? listFind('禁用下车点')
                          ? true
                          : false
                        : scope.scopeRow.status === 1
                        ? listFind('启用下车点')
                          ? true
                          : false
                        : false
                    "
                  >
                    <el-button
                      type="text"
                      size="small"
                      :sort="scope.scopeRow.status === 0 ? 'danger' : 'primary'"
                      @click="onDisable3(scope.scopeRow)"
                      >{{
                        scope.scopeRow.status === 0 ? '禁用' : '启用'
                      }}</el-button
                    >
                  </template>
                  <template v-if="listFind('删除下车点')">
                    <el-button
                      v-if="scope.scopeRow.status === 0"
                      type="text"
                      size="small"
                      sort="danger"
                      @click="onDelete3(scope.scopeRow)"
                      >删除</el-button
                    >
                  </template>
                </div>
              </template>
            </Table>
            </div>
            
          </el-tab-pane>
          <!-- 7.5版本迭代 -->
          <el-tab-pane label="途经点">
            <el-button
              v-if="listFind('新增途径点')"
              style="margin-bottom: 14px"
              type="primary"
              size="small"
              @click="onAdd4('新增')"
              >新增途径点</el-button
            >
            <Table
              :table-data="passPointData"
              :title-name="passPointtitleName"
              :col-class-type="true"
              :operation="true"
              operation-width="160"
              :table-height="bottomTableHeight"
            >
              <template slot-scope="scope">
                <div>
                  <!-- 线路站点状态1-禁用 0-启用 -->
                  <template v-if="listFind('编辑途径点')">
                    <el-button
                      v-if="scope.scopeRow.lineStationStatus === 0"
                      type="text"
                      size="small"
                      sort="primary"
                      @click="onUpdate4(scope.scopeRow)"
                      >编辑</el-button
                    >
                  </template>
                  <template
                    v-if="
                      scope.scopeRow.lineStationStatus === 0
                        ? listFind('禁用途径点')
                          ? true
                          : false
                        : scope.scopeRow.lineStationStatus === 1
                        ? listFind('启用途径点')
                          ? true
                          : false
                        : false
                    "
                  >
                    <el-button
                      type="text"
                      size="small"
                      :sort="
                        scope.scopeRow.lineStationStatus === 0
                          ? 'danger'
                          : 'primary'
                      "
                      @click="onDisable4(scope.scopeRow)"
                    >
                      {{
                        scope.scopeRow.lineStationStatus === 0 ? '禁用' : '启用'
                      }}
                    </el-button>
                  </template>
                  <template v-if="listFind('删除途径点')">
                    <el-button
                      v-if="scope.scopeRow.lineStationStatus === 0"
                      type="text"
                      size="small"
                      sort="danger"
                      @click="onDelete4(scope.scopeRow)"
                      >删除</el-button
                    >
                  </template>
                </div>
              </template>
            </Table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 新增计划车次 -->
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible1"
        :close-on-click-modal="false"
        width="440px"
        @close="onDialogClose1"
      >
        <div style="height: 500px; overflow-y: auto">
          <div class="body">
            <el-form
              ref="addForm1"
              :model="addForm1"
              :rules="rules"
              label-width="100px"
              label-position="left"
              class="demo-form dialog-form"
            >
              <!-- <el-form-item label="车次名称：" prop="name">
                <el-input
                  v-model.trim="addForm1.name"
                  :disabled="CARdisabled"
                  placeholder="请输入车次名称"
                  clearable
                ></el-input>
              </el-form-item> -->
              <el-form-item label="班次类型：" prop="shiftType">
                <el-radio-group
                  v-model="addForm1.shiftType"
                  @input="radioChange"
                  :disabled="title == '修改'"
                >
                  <el-radio :label="0">定班</el-radio>
                  <el-radio :label="1">流水班</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="开始时间：" prop="startDepartureTime">
                <el-time-picker
                  v-model="addForm1.startDepartureTime"
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="选择发车开始时间"
                  @change="handleStartTime"
                ></el-time-picker>
              </el-form-item>
              <el-form-item label="结束时间：" prop="endDepartureTime">
                <el-time-picker
                  v-model="addForm1.endDepartureTime"
                  format="HH:mm"
                  value-format="HH:mm"
                  :disabled="addForm1.shiftType === 0"
                  placeholder="选择发车结束时间"
                ></el-time-picker>
              </el-form-item>
              <el-form-item label="票位数量：" prop="ticketNum">
                <el-input
                  v-model.trim="addForm1.ticketNum"
                  placeholder="请输入票位数量"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="title == '修改' && tableData0 && tableData0.length && listFind('委托售票-禁止开放')"
                label="委托售票："
                prop=""
              >
                <template>
                  <div
                    v-for="(item, index) in tableData0"
                    :key="index"
                    class="row-style"
                  >
                    <el-form-item
                      :label="`${item.authorizeName}：`"
                      :prop="item.systemNo"
                    >
                      <el-input
                        class="inner-input"
                        :disabled="addForm1.authorizeVehicleShiftIntercitys[index].status == 1"
                        v-model.trim="
                          addForm1.authorizeVehicleShiftIntercitys[index]
                            .ticketNum
                        "
                        @change="bluerInput($event, addForm1.ticketNum)"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-form-item>
              <el-form-item label="车辆类型：" prop="level">
                <el-select
                  v-model="addForm1.level"
                  clearable
                  placeholder="请选择车辆类型"
                >
                  <el-option
                    v-for="role in levelList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="班次状态：" prop="status">
                <el-select
                  v-model="addForm1.status"
                  clearable
                  placeholder="请选择班次状态"
                >
                  <el-option
                    v-for="role in statusList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开班属性：" prop="type">
                <el-select
                  v-model="addForm1.type"
                  clearable
                  placeholder="请选择车次类型"
                >
                  <el-option
                    v-for="role in carTypeList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="在线选座：" prop="onlineSeatStatus">
                <el-select
                  v-model="addForm1.onlineSeatStatus"
                  placeholder="请选择是否启用"
                  @change="onlineSeatFun"
                >
                  <el-option
                    v-for="role in seatStatusList"
                    :key="role.value"
                    :label="role.label"
                    :value="role.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="seatPlanIdProp"
                label="座位布局："
                :prop="seatPlanIdProp"
              >
                <el-select
                  :filterable="true"
                  :loading="selectLoading"
                  :disabled="seatPlanIdProp ? false : true"
                  v-model="addForm1.seatPlanId"
                  clearable
                  placeholder="请选择座位布局"
                  @change="seatChangeFun"
                >
                  <el-option
                    v-for="role in seatLayoutList"
                    :key="role.id"
                    :label="role.seatName"
                    :value="role.id"
                  ></el-option>
                </el-select>
                <div
                  v-show="seatPlanIdProp && addForm1.seatPlanId"
                  class="seatPreview"
                  @click="seatPreviewFun"
                >
                  预览
                </div>
              </el-form-item>
              <el-form-item label="优惠票价：">
                <el-input
                  v-model="addForm1.discountTicketPrice"
                  onkeyup="this.value = this.value.replace(/[^\d|.]/g,'');"
                  clearable
                  placeholder="请填写优惠票价"
                ></el-input>
              </el-form-item>
              <el-form-item label="优惠开始日期：">
                <el-date-picker
                  v-model="addForm1.discountStartTime"
                  type="date"
                  placeholder="选择开始日期"
                  @change="form1StartTime"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="优惠截止日期：">
                <el-date-picker
                  v-model="addForm1.discountEndTime"
                  type="date"
                  placeholder="选择截止日期"
                  @change="form2StartTime"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="提示信息：">
                <el-input
                  style="width:200px"
                  type="textarea"
                  maxlength="100"
                  :rows="4"
                  placeholder="显示在乘客端班次列表，仅该班次会显示。最多填写100字。"
                  v-model="addForm1.wayPoint"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            size="small"
            @click="
              () => {
                this.dialogVisible1 = false
              }
            "
            >取消</el-button
          >
          <el-button type="primary" size="small" @click="onSave1"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <!-- 新增上车点弹窗 -->
      <el-dialog
        v-if="dialogVisible2"
        :title="title"
        :visible.sync="dialogVisible2"
        :close-on-click-modal="false"
        width="1200px"
        top="5vh"
        @close="onDialogClose2"
      >
        <div class="mapBody">
          <div class="topForm">
            <el-row>
              <el-form
                ref="addForm2"
                :model="addForm2"
                :rules="rules"
                :inline="true"
                label-width="100px"
                label-position="top"
              >
                <el-col :span="5">
                  <el-form-item label="位置类型：" prop="areaMode">
                    <el-select
                      v-model="addForm2.areaMode"
                      placeholder="请选择位置类型"
                      @change="locationChangeFun"
                      :disabled="title == '修改'"
                    >
                      <el-option
                        v-for="role in areaModeList"
                        :key="role.value"
                        :label="role.label"
                        :value="role.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="位置名称：" prop="name">
                    <el-input
                      v-model.trim="addForm2.name"
                      placeholder="请输入位置名称"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="接驾时间：" prop="pickUpTime">
                    <div class="time">
                      <el-input v-model="addForm2.pickUpTime" type="number" />
                      <span>分钟</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col v-if="listFind('上车点溢价')" :span="5">
                  <el-form-item label="溢价接驾(元)：" prop="floatingPrice">
                    <div class="time">
                      <el-input
                        v-model="addForm2.floatingPrice"
                        type="number"
                      />
                      <span>元</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="上车点启用：" prop="status">
                    <el-switch
                      v-model="addForm2.status"
                      active-color="#13ce66"
                      :active-value="0"
                      :inactive-value="1"
                    ></el-switch>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-row>
          </div>
          <div class="bottomMap">
            <edit-map
              :id="addForm2.id + `-` + addForm2.name"
              ref="editmap"
              :title="title"
              :location-type="locationType"
              :area-content="addForm2.areaContent"
              :station="region[0]"
              :add-form2-copy="addForm2Copy"
              :area-content-list="locationType == 1 ? upData : []"
              @positionVariableFun="positionVariableFun"
              @areaContent="areaContentFUN"
              @changeAreaContent="changeAreaContent"
            />
          </div>
          <div v-if="locationType == 1" class="dot-tips">
            <header>
              <div class="dot"></div>
              <span>定位点</span>
              <span style="margin-left: 20px"
                >定位点建议不超过160个，当前已创建{{ dotNum }}个</span
              >
            </header>
            <div class="tip">定位点超过上限后，会导致高德地图识别失败</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="default" size="small" @click="onDialogClose2"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            :loading="editLoading"
            :disabled="dotNum >= 160"
            @click="onSave2"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        v-if="dialogVisible3"
        :title="title"
        :visible.sync="dialogVisible3"
        :close-on-click-modal="false"
        width="1200px"
        top="5vh"
        @close="onDialogClose3"
      >
        <div class="mapBody">
          <div class="topForm">
            <el-row>
              <el-form
                ref="addForm3"
                :model="addForm3"
                :rules="rules"
                :inline="true"
                label-width="100px"
                label-position="top"
              >
                <el-col :span="6">
                  <el-form-item label="位置类型：" prop="areaMode">
                    <el-select
                      v-model="addForm3.areaMode"
                      placeholder="请选择位置类型"
                      @change="locationChangeFun"
                      :disabled="title == '修改'"
                    >
                      <el-option
                        v-for="role in areaModeList"
                        :key="role.value"
                        :label="role.label"
                        :value="role.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="位置名称：" prop="name">
                    <el-input
                      v-model.trim="addForm3.name"
                      placeholder="请输入位置名称"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col v-if="listFind('下车点溢价')" :span="6">
                  <el-form-item label="溢价送驾：" prop="floatingPrice">
                    <div class="time">
                      <el-input
                        v-model="addForm3.floatingPrice"
                        type="number"
                      />
                      <span>元</span>
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="下车点启用：" prop="status">
                    <el-switch
                      v-model="addForm3.status"
                      active-color="#13ce66"
                      :active-value="0"
                      :inactive-value="1"
                    ></el-switch>
                  </el-form-item>
                </el-col>
              </el-form>
            </el-row>
          </div>
          <div class="bottomMap">
            <edit-map
              :id="addForm3.id + `-` + addForm3.name"
              ref="editmap"
              :title="title"
              :location-type="locationType"
              :area-content="addForm3.areaContent"
              :station="region[1]"
              :add-form2-copy="addForm2Copy"
              :area-content-list="locationType == 1 ? downData : []"
              @areaContent="areaContentFUN"
              @positionVariableFun="positionVariableFun"
              @changeAreaContent="changeAreaContent"
            />
          </div>
          <div v-if="locationType == 1" class="dot-tips">
            <header>
              <div class="dot"></div>
              <span>定位点</span>
              <span style="margin-left: 20px"
                >定位点建议不超过160个，当前已创建{{ dotNum }}个</span
              >
            </header>
            <div class="tip">定位点超过上限后，会导致高德地图识别失败</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="default" size="small" @click="onDialogClose3"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            :loading="editLoading"
            :disabled="dotNum >= 160"
            @click="onSave3"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <!-- 新增|编辑 -->
      <el-dialog
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisible"
        width="1100px"
        @close="onDialogClose"
      >
        <div class="addFormbody">
          <el-form
            ref="addForm"
            :model="addForm"
            :rules="rules"
            label-width="110px"
            label-position="right"
            class="demo-form dialog-form"
          >
            <div class="classify">基本信息</div>
            <div class="classify-content">
              <el-form-item label="线路名称：" prop="name">
                <el-input
                  v-model.trim="addForm.name"
                  placeholder="请输入线路名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="所属公司：" prop="companyId">
                <companySelect
                  ref="companySelect"
                  :disabled="editDisabled"
                  :clearable="true"
                  :company-tree="companyList"
                  :valuename="valueAddName"
                  :value="valueAdd"
                  @getName="getAddGroupName"
                  @getValue="getAddGroupId"
                ></companySelect>
              </el-form-item>
              <el-form-item label="所属主线路：" prop="mainLineCode">
                <el-select
                  v-model="addForm.mainLineCode"
                  clearable
                  filterable
                  placeholder="请选择所属主线路"
                >
                  <el-option
                    v-for="(role, index) in mainLineList"
                    :key="index"
                    :label="role.lineName"
                    :value="role.lineCode"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="起点站：" prop="startStation">
                <el-input
                  v-model.trim="addForm.startStation"
                  placeholder="请输入起点站"
                ></el-input>
              </el-form-item>
              <el-form-item label="终点站：" prop="endStation">
                <el-input
                  v-model.trim="addForm.endStation"
                  placeholder="请输入终点站"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="参营公司："
                prop="participateOperateCompanyIds"
              >
                <div class="tips-out">
                  <companySelect
                    ref="companySelectOpare"
                    :clearable="true"
                    :multiple="true"
                    :company-tree="companyList"
                    :valuenameMultiple="participateValueAddName"
                    :valueMultiple="participateValueAdd"
                    @getName="getAddGroupNameParticipate"
                    @getValue="getAddGroupIdParticipate"
                  ></companySelect>
                  <div
                    v-if="
                      addForm.participateOperateCompanyIds &&
                      addForm.participateOperateCompanyIds.length &&
                      editDisabled
                    "
                    class="company-tips"
                  >
                    <i class="el-icon-warning"></i
                    >取消参营公司，需先取消派车信息，再解除车辆和线路的绑定关系
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="线路方向：" prop="lineDirection">
                <el-select
                  v-model="addForm.lineDirection"
                  clearable
                  placeholder="请选择线路方向"
                >
                  <el-option label="去程" :value="1"></el-option>
                  <el-option label="返程" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="addForm.lineDirection == 2"
                label="去程线路："
              >
                <el-select
                  v-model="addForm.itineraryLineId"
                  filterable
                  remote
                  reserve-keyword
                  clearable
                  :remote-method="queryChildrenList"
                  placeholder="无去程线路可不填写"
                  @focus="queryChildrenList2"
                >
                  <el-option
                    v-for="(role, index) in childrenList"
                    :key="index"
                    :disabled="role.isBind"
                    :label="role.name"
                    :value="role.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-show="listFind('商品编码')" label="商品编码：">
                <el-input
                  v-model.trim="addForm.wechatPayGoodsId"
                  placeholder="请输入商品编码"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="addForm.lineDirection != 2"
                label=""
                style="opacity: 0"
              >
                <el-input></el-input>
              </el-form-item>
            </div>
            <div class="classify">车票信息</div>
            <div class="classify-content">
              <el-form-item label="核定价：" prop="ticketPrice">
                <el-input
                  v-model.trim="addForm.ticketPrice"
                  placeholder="请输入核定价"
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="估计用时：" prop="drivingTime">
                <el-input
                  v-model.trim="addForm.drivingTime"
                  placeholder="请输入估计用时(分钟)"
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                ></el-input>
              </el-form-item>
              <el-form-item label="购票类型：" prop="isRealName">
                <div class="tips-out">
                  <el-select
                    v-model="addForm.isRealName"
                    clearable
                    placeholder="请选择购票类型"
                  >
                    <el-option
                      v-for="role in isRealNameList"
                      :key="role.id"
                      :label="role.title"
                      :value="role.id"
                    ></el-option>
                  </el-select>
                  <div v-if="editDisabled" class="company-tips is-realName">
                    <i class="el-icon-warning"></i>购票类型变更后次日生效
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="classify">服务信息</div>
            <div class="classify-content" style="margin-bottom: 0">
              <el-form-item label="订单提醒号码：" prop="phone">
                <div class="addNumberBox">
                  <div
                    v-for="(item, index) in phoneNumberArray"
                    :key="index"
                    style="position: relative"
                  >
                    <el-input
                      v-model.trim="phoneNumberArray[index]"
                      maxlength="11"
                      clearable
                      placeholder="请输入订单提醒号码"
                      onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                    ></el-input>
                    <el-button
                      v-show="index >= 1"
                      class="reduce"
                      @click="reducePhoneNumberFun(index)"
                      >删除</el-button
                    >
                  </div>
                  <el-button
                    v-show="this.phoneNumberArray.length < 5"
                    class="add"
                    @click="addPhoneNumberFun"
                  >
                    <i class="iconfont its_zengjia"></i>添加更多
                  </el-button>
                </div>
              </el-form-item>
            </div>
            <div class="classify-content" style="margin: 0">
              <el-form-item label="客服电话：" prop="customerPhone">
                <div class="addNumberBox">
                  <div
                    v-for="(item, index) in customerServicePhone"
                    :key="index"
                    style="position: relative"
                  >
                    <el-input
                      v-model.trim="customerServicePhone[index]"
                      maxlength="11"
                      clearable
                      placeholder="请输入客服电话号码"
                      onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                    ></el-input>
                    <el-button
                      v-show="index >= 1"
                      class="reduce"
                      @click="reducecustomerFun(index)"
                      >删除</el-button
                    >
                  </div>
                  <el-button
                    v-show="this.customerServicePhone.length < 5"
                    class="add"
                    @click="addcustomerFun"
                  >
                    <i class="iconfont its_zengjia"></i>添加更多
                  </el-button>
                </div>
              </el-form-item>
            </div>
            <div class="classify-content" style="margin: 0">
              <el-form-item label="线路标签：" prop="lineLabels">
                <el-tag
                  :key="tag"
                  v-for="tag in lineLabels"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                  style="margin-right: 10px"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @input="limitLength"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                >
                </el-input>
                <el-button
                  v-else-if="!inputVisible && lineLabels.length <= 1"
                  class="button-new-tag"
                  size="small"
                  @click="showInput"
                  >+ 最多填写8个字</el-button
                >
              </el-form-item>
            </div>
            <div class="classify-content" style="margin-top: 0">
              <el-form-item label="提示信息：" prop="lineTipsInfo">
                <div class="textareaBox">
                  <el-input
                  type="textarea"
                  maxlength="100"
                  :rows="6"
                  placeholder="显示在乘客端班次列表，该线路所有班次均会显示。"
                  v-model="addForm.lineTipsInfo"
                ></el-input>
                </div>
                
              </el-form-item>
            </div>
            <div class="classify-content" style="margin-top: 0">
              <el-form-item label="线路通知：" prop="notice">
                <div class="addNumberBox textareaBox">
                  <div>
                    <el-input
                    type="textarea"
                    maxlength="100"
                    :rows="6"
                    placeholder="请输入通知内容，通知内容将展示在乘客端的班次列表页"
                    v-model="addForm.notice"
                  ></el-input>
                  <div class="company-tips is-realName"><i class="el-icon-warning"></i
                    >通知内容请勿手动输入大括号{ }，以免导致显示错误。</div>
                  </div>                  
                  <el-button class="add" @click="addPhoneNumberLineFun">
                    <i class="iconfont its_zengjia"></i>插入联系电话
                  </el-button>
                </div>
              </el-form-item>
            </div>
            <div class="classify">售票渠道</div>
            <div class="classify-content">
              <el-form-item label="所属渠道：" prop="channelNo">
                <el-select
                  v-model="addForm.channelNo"
                  :disabled="editDisabled"
                  placeholder="请选择所属渠道"
                  @change="channelNoFun"
                >
                  <el-option
                    v-for="role in channelList"
                    :key="role.channelNo"
                    :label="role.channelName"
                    :value="role.channelNo"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="listFind('可售渠道')"
                label="可售渠道："
                prop="vendibilityChannelNo"
              >
                <el-select
                  v-model="addForm.vendibilityChannelNo"
                  multiple
                  :collapse-tags="true"
                  placeholder="请选择可售渠道"
                >
                  <el-option
                    v-for="role in vendibilityList"
                    :key="role.channelNo"
                    :label="role.channelName"
                    :value="role.channelNo"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" style="opacity: 0">
                <el-input></el-input>
              </el-form-item>
            </div>
            <el-form-item
              v-show="listFind('微信二维码')"
              label="微信二维码："
              :prop="wxQrCode"
              style="display: block"
            >
              <el-upload
                ref="elUpload"
                action
                accept=".jpg,.png"
                list-type="picture-card"
                class="upload-demo"
                :auto-upload="false"
                :multiple="false"
                :on-change="imageSuccessFun"
                :on-remove="imageListRemove"
                :file-list="fileList"
                :limit="2"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过240kb
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="线路启用：">
              <el-switch
                inactive-color="#E3E3E3"
                active-color="#336ffe"
                v-model="addForm.status"
              ></el-switch>
            </el-form-item>
            <el-form-item v-if="title == '新增'" label="账号绑定：">
              <el-switch
                inactive-color="#E3E3E3"
                active-color="#336ffe"
                v-model="addForm.accountBinding"
              ></el-switch>
              <p>
                开启状态，线路所属公司及上级公司的账号均会绑定该线路。关闭状态，该线路不绑定账号
              </p>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            size="small"
            @click="
              () => {
                seeShow = true
                dialogVisible = false
                fileList = []
              }
            "
            >取消</el-button
          >
          <el-button
            v-show="seeShow"
            type="primary"
            size="small"
            :loading="editLoading"
            @click="onSave"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <seatSelectionDialog ref="seatSelectionDialog"></seatSelectionDialog>
      <div v-show="imgPopup" class="imgPopup" @click="() => (imgPopup = false)">
        <img :src="imgPopupImageUrl" alt @click.stop="() => {}" />
      </div>
      <!-- 开班属性弹窗 -->
      <charterPopup
        v-if="shiftPopupShow"
        :width="900"
        headerText="开班属性"
        @confirmFun="confirmFun"
        @closePopupFun="clearForm"
      >
        <div class="shift-attribute-pop">
          <div class="shift-title">开班设置</div>
          <div class="shift-box">
            <div class="shift-content">
              <div class="shift-label">基准日期：</div>
              <el-date-picker
                class="shiftDatePicker"
                v-model="shiftDatePickerValue"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
              <span style="color: #ccc">（开始执行日期）</span>
            </div>
            <div class="shift-content">
              <div class="shift-label">开班属性：</div>
              <el-select
                class="shift-select"
                v-model="shiftListValue"
                placeholder="请选择"
                @change="shiftCourseFun"
              >
                <el-option
                  v-for="item in shiftList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
              <div v-if="shiftListValue" class="shift-course-text">
                <i class="iconfont its_cuowu"></i>
                {{ shiftCourseLabel }}
              </div>
            </div>
            <div v-if="shiftStageShow" class="shift-content">
              <div class="shift-label">
                阶段{{ shiftListValue == 2 ? '开' : '停' }}班时间段：
              </div>
              <div class="shift-stage">
                <el-date-picker
                  class="shift-stage-date"
                  v-model="shiftAddDateValue"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <el-button
                  type="primary"
                  size="small"
                  style="margin-bottom: 10px"
                  @click="shiftAddDateFun"
                  >添加</el-button
                >
                <Table
                  class="shiftTable"
                  :table-data="shiftTableData"
                  :title-name="shiftTableTitleName"
                  :operation="true"
                  :table-height="shiftTableData.length > 0 ? 200 : 100"
                  operation-width="150"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      sort="primary"
                      @click="shiftDeleteFun(scope.scopeRow)"
                      >删除</el-button
                    >
                  </template>
                </Table>
              </div>
            </div>
          </div>
          <div class="shift-title" style="margin-top: 30px">排班设置</div>
          <div class="shift-box">
            <div class="shiftA">
              <div class="shift-label">
                {{
                  shiftListValue == 3 || shiftListValue == 4 ? '停' : '开'
                }}班属性：
              </div>
              <el-radio v-model="shiftAradio" label="1">周期性</el-radio>
              <el-radio v-model="shiftAradio" label="2">按日历</el-radio>
              <div class="shiftA-box">
                <div style="margin-bottom: 16px">
                  <el-select
                    class="shift-select"
                    v-model="attributeValue"
                    placeholder="请选择"
                    @change="shiftAttributeChange"
                  >
                    <el-option
                      v-for="item in attributeList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </div>
                <el-checkbox-group
                  v-if="shiftCheckedShow"
                  v-model="shiftAttributeCheckedValue"
                >
                  <el-checkbox
                    v-for="item in attributeChecked"
                    :key="item"
                    :label="item"
                  ></el-checkbox>
                </el-checkbox-group>
                <div v-if="shiftCalendarShow" class="shift-calendar">
                  <div
                    v-for="item in calendarNum"
                    :key="item"
                    :class="calendarClassFun(item)"
                    @click="calendarFun(item)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </charterPopup>
      <!-- 复制车次弹窗 -->
      <el-dialog
        :close-on-click-modal="false"
        title="复制班次"
        :visible.sync="copyPopup"
        width="440px"
        @close="copyPopupClose"
      >
        <div>
          <div class="body">
            <el-form
              ref="copyForm"
              :model="copyForm"
              :rules="rulesCopy"
              label-width="100px"
              label-position="left"
              class="demo-form dialog-form"
            >
              <!-- <el-form-item label="车次名称：" prop="name">
                <el-input
                  v-model.trim="copyForm.name"
                  placeholder="请输入车次名称"
                  clearable
                ></el-input>
              </el-form-item> -->
              <el-form-item label="开始时间：" prop="startDepartureTime">
                <el-time-picker
                  v-model="copyForm.startDepartureTime"
                  format="HH:mm"
                  value-format="HH:mm"
                  placeholder="选择开始时间"
                  @change="handleStartCopyTime"
                ></el-time-picker>
              </el-form-item>
              <el-form-item label="结束时间：" prop="endDepartureTime">
                <el-time-picker
                  v-model="copyForm.endDepartureTime"
                  format="HH:mm"
                  :disabled="copyShiftType === 0"
                  value-format="HH:mm"
                  placeholder="选择结束时间"
                ></el-time-picker>
              </el-form-item>
              <el-form-item v-if="copyisShow" label="开班属性：">
                <el-input
                  type="textarea"
                  :disabled="true"
                  :rows="4"
                  v-model="copyTextarea"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            size="small"
            @click="
              () => {
                this.copyPopup = false
              }
            "
            >取消</el-button
          >
          <el-button type="primary" size="small" @click="copyCommit"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <!-- 新增途经点 -->
      <el-dialog
        :close-on-click-modal="false"
        :title="passPointTitle"
        :visible.sync="dialogVisiblePassPoint"
        v-if="dialogVisiblePassPoint"
        width="520px"
        @close="onDialogClosePassPoint"
      >
        <el-form
          ref="addFormPassPoint"
          :model="addFormPassPoint"
          :rules="rulesPassPoint"
          label-width="138px"
          label-position="left"
          class="demo-form dialog-form"
        >
          <el-form-item label="途经点名称：" prop="stationId">
            <el-select
              v-model="addFormPassPoint.stationId"
              clearable
              placeholder="请选择途经点"
              :disabled="isDisabled"
              @change="passStationChange"
            >
              <el-option
                v-for="element in stationList"
                :key="element.id"
                :label="element.name"
                :value="element.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="显示名称：" prop="stationAlias">
            <el-input
              v-model.trim="addFormPassPoint.stationAlias"
              placeholder="请输入显示名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="途经点类型：" prop="stationType">
            <el-select
              v-model="addFormPassPoint.stationType"
              placeholder="请选择途经点类型"
              disabled
            >
              <el-option
                v-for="role in areaPassModeList"
                :key="role.value"
                :label="role.label"
                :value="role.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="距出发地估计用时：" prop="timeEstimation">
            <el-input
              v-model.trim="addFormPassPoint.timeEstimation"
              placeholder="请输入用时(分钟)"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="距出发地票价：" prop="price">
            <el-input
              v-model.trim="addFormPassPoint.price"
              placeholder="请输入票价(元)"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="default" size="small" @click="onDialogClosePassPoint"
            >取消</el-button
          >
          <el-button type="primary" size="small" @click="onSavePassPoint"
            >保存</el-button
          >
        </span>
      </el-dialog>
      <!-- 委托售票 -->
      <el-dialog
        :close-on-click-modal="false"
        title="委托售票"
        :visible.sync="dialogVisibleEntrustPop"
        v-if="dialogVisibleEntrustPop"
        width="520px"
        @close="onDialogCloseEntrust"
      >
        <div class="body">
          <el-form
            ref="addEntrustForm"
            :model="addEntrustForm"
            :rules="rulesEntrust"
            label-width="138px"
            label-position="right"
            class="demo-form dialog-form"
          >
            <el-form-item label="委托售票平台：" prop="systemNo">
              <el-select
                v-model="addEntrustForm.systemNo"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in systemNoList"
                  :label="item.desc"
                  :value="item.no"
                  :key="item.no"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="班次委托售票比例：" prop="authorizeRatio">
              <el-input
                type="number"
                placeholder="请输入"
                v-model.trim="addEntrustForm.authorizeRatio"
                clearable
              >
              </el-input>
              <!-- <el-slider v-model="addEntrustForm.authorizeRatio" :max="100" :min="1" show-input> </el-slider> -->
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="default" size="small" @click="onDialogCloseEntrust"
            >取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            :loading="editLoading"
            @click="entrustSave"
          >
            确认
          </el-button>
        </span>
      </el-dialog>
      <!-- 插入联系 -->
      <el-dialog
        :close-on-click-modal="false"
        title="插入联系电话"
        :visible.sync="dialogVisibleInputPhone"
        v-if="dialogVisibleInputPhone"
        width="420px"
        @close="onDialogCloseInputPhone"
      >
        <el-form
          ref="addFormInputPhone"
          :model="addFormInputPhone"
          :rules="rulesInputPhone"
          label-width="96px"
          label-position="left"
          class="demo-form dialog-form"
        >
          <el-form-item label="联系电话：" prop="customerPhone">
            <el-input
              v-model.trim="addFormInputPhone.customerPhone"
              maxlength="11"
              clearable
              placeholder="请输入联系号码"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            ></el-input>
          </el-form-item>          
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
              type="default"
              size="small"
              @click="onDialogCloseInputPhone"
              >取消</el-button
            >
            <el-button type="primary" size="small" @click="onSaveInputPhone"
              >保存</el-button
            >
          </span>
      </el-dialog>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
  </div>
</template>

<script>
let ticketNumTime = null;
// import { checkPhone } from "@/common/utils/index.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import lineRules from "./components/lineRules.vue";
import {
  getCompanyTree,
  startStationAPI,
  endStationAPI,
  linePageAPI,
  lineDisableAPI,
  queryLineList,
  queryShiftList,
  queryAreaList,
  updateShift,
  disableShift,
  insertShift,
  insertLine,
  updateLine,
  deleteLine,
  deleteShift,
  insertArea,
  disableArea,
  updateArea,
  deleteArea,
  configureParamsAPI,
  linePointAPI,
  updateLinePointAPI,
  queryIntercityMainLineListAPI,
  getCurrentUserChannelInfoAPI,
  uploadFileAPI,
  queryBySeatNameAPI,
  updateVehicleShiftRegulationAPI,
  cloneTrainAPI,
  queryVehicleShiftRegulationInfoAPI,
  queryIntercityLineStationRelationPage, //途经点列表
  queryIntercityStationList,
  saveIntercityLineStationRelation,
  updateIntercityLineStationRelation,
  deleteMidwayStationById,
  queryIntercityMainLinePageAPI,
  lineListTrip,
  lineAuthorizeConfigByLineId,
  getSystemNoList,
  addOrUpdateLineAuthorizeConfig,
  deleteLineAuthorizeConfig,
  areaAdjustmentSerial,
} from "@/api/lib/api.js";
import viewMap from "./viewMap/viewMap.vue";
import editMap from "./editMap/editMap.vue";
import host from "@/api/static/host.js";
import seatSelectionDialog from "./seatSelectionDialog.vue";
import {
  checkPositiveInteger,
  checkPositiveNumber,
  checkPercentNumber,
  checkPhone,
} from "@/common/utils/index.js";
export default {
  components: {
    companySelect,
    editMap,
    viewMap,
    seatSelectionDialog,
    lineRules,
  },
  data () {
    //开始时间小于等于结束时间
    const validateTimeForward = (rule, value, callback) => {
      if (value && this.addForm1.endDepartureTime) {
        if (value > this.addForm1.endDepartureTime) {
          callback(new Error("开始时间需要小于结束时间"));
        } else {
          callback();
        }
      }
    };
    //结束时间大于等于开始时间
    const validateTimeBackward = (rule, value, callback) => {
      if (value && this.addForm1.startDepartureTime) {
        //流水班结束时间需要大于开始时间
        if (this.addForm1.shiftType == 1) {
          if (value > this.addForm1.startDepartureTime) {
            callback();
          } else {
            callback(new Error("结束时间需要大于开始时间"));
          }
        } else {
          if (value == this.addForm1.startDepartureTime) {
            callback();
          } else {
            callback(new Error("结束时间需要大于开始时间"));
          }
        }
      }
    };
    //开始时间小于等于结束时间
    const validateTimeForwardCopy = (rule, value, callback) => {
      if (value && this.copyForm.endDepartureTime) {
        if (value > this.copyForm.endDepartureTime) {
          callback(new Error("开始时间需要小于结束时间"));
        } else {
          callback();
        }
      }
    };
    //结束时间大于等于开始时间
    const validateTimeBackwardCopy = (rule, value, callback) => {
      if (value && this.copyForm.startDepartureTime) {
        //流水班结束时间需要大于开始时间
        if (this.copyShiftType == 1) {
          if (value > this.copyForm.startDepartureTime) {
            callback();
          } else {
            callback(new Error("结束时间需要大于开始时间"));
          }
        } else {
          if (value == this.copyForm.startDepartureTime) {
            callback();
          } else {
            callback(new Error("结束时间需要大于开始时间"));
          }
        }
      }
    };
    return {
      tableHeight: null,
      pageLoading: true,
      emptyFlag: false,
      emptyText: "暂无数据",
      calendarNum: 31,
      copyisShow: true,
      copyID: null,
      copyShiftType: null,
      copyTextarea: "",
      copyPopup: false,
      copyForm: {
        name: "",
        startDepartureTime: "",
        endDepartureTime: "",
      },
      shiftAddDateValue: "",
      shiftID: null,
      shiftPopupShow: false, //开班属性弹窗开关
      calendarArray: [],
      shiftTableData: [],
      shiftTableTitleName: [
        {
          title: "开始日期",
          props: "startYearMonthDay",
        },
        {
          title: "结束日期",
          props: "endYearMonthDay",
        },
      ],
      shiftCalendarShow: false,
      shiftCourseLabel: "从基准日期之后，将会长期开班，直到开班设置发生改变",
      shiftStageShow: false,
      shiftCheckedShow: false,
      shiftAttributeCheckedValue: [],
      attributeChecked: [
        "星期天",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      attributeValue: 1,
      attributeList: [
        {
          id: 1,
          label: "天天班",
        },
        {
          id: 2,
          label: "按星期",
        },
      ],
      shiftListValue: null,
      shiftAradio: "1",
      shiftList: [
        {
          id: 1,
          label: "长期开班",
        },
        {
          id: 2,
          label: "阶段开班",
        },
        {
          id: 3,
          label: "长期停班",
        },
        {
          id: 4,
          label: "阶段停班",
        },
      ],
      shiftDatePickerValue: "", // 开班属性-基准日期
      shiftAttribute: [],
      shiftDate: "",
      imgPopup: false,
      imgPopupImageUrl: "",
      channelNo: null,
      isRealNameList: [
        { id: 0, title: "非实名" },
        { id: 1, title: "实名" },
      ],
      ticketPriceAll: "",
      CARdisabled: false,
      phoneNumberArray: [""], // 订单提醒号码
      customerServicePhone: [""], //客服电话
      positionVariable: undefined,
      editDisabled: false,
      seeShow: true,
      disabled: false,
      areaModeList: [
        { value: 0, label: "区域" },
        { value: 1, label: "固定点" },
      ],
      //途经点类型列表 0:车站 1:站点 2:区域
      areaPassModeList: [
        { value: 0, label: "车站" },
        { value: 1, label: "站点" },
        { value: 2, label: "区域" },
      ],
      editLoading: false,
      title: "",
      carTypeList: [
        { value: 0, label: "长期开班，次日生效" },
        { value: 1, label: "不自动开班" },
      ],
      seatStatusList: [
        { value: 0, label: "是" },
        { value: 1, label: "否" },
      ],
      addForm1: {
        lineId: "",
        // name: "",
        startDepartureTime: "",
        endDepartureTime: "",
        level: "",
        ticketNum: "",
        status: "",
        type: "",
        discountTicketPrice: "",
        discountStartTime: "",
        discountEndTime: "",
        onlineSeatStatus: "", // 在线选座状态 0：启用  1：禁用
        seatPlanId: "",
        seatName: "",
        wayPoint: "",
        shiftType: 0, //班次类型0：定班1：流水班
        authorizeVehicleShiftIntercitys: [{
          ticketNum: null
        }],
      },
      addForm2Copy: {},
      addForm2: {
        name: "",
        lineId: "",
        areaMode: "",
        areaType: 0,
        areaContent: undefined,
        status: "",
        pickUpTime: "",
        floatingPrice: "",
      },
      addForm3: {
        name: "",
        lineId: "",
        areaMode: "",
        areaType: 1,
        areaContent: undefined,
        status: "",
        floatingPrice: "",
      },
      upData: [],
      downData: [],
      total: 0,
      tableData0: [],
      tableData: [],
      tableData2: [],
      titleName0: [
        {
          title: "委托售票平台",
          props: "authorizeName",
        },
        {
          title: "班次委托售票比例",
          props: "authorizeRatio",
        },
      ],
      systemNoList: [],
      rowIndex:null,
      uptitleName: [
        {
          title: "名称",
          props: "name",
        },
        {
          title: "类型",
          props: "areaMode",
          SpecialJudgment: (res) => {
            return res == 0 ? "区域 " : "固定点";
          },
        },
        {
          title: "接驾时间",
          props: "pickUpTime",
        },
        {
          title: "溢价接驾(元)",
          props: "floatingPrice",
        },
      ],
      downtitleName: [
        {
          title: "名称",
          props: "name",
        },
        {
          title: "类型",
          props: "areaMode",
          SpecialJudgment: (res) => {
            return res == 0 ? "区域 " : "固定点";
          },
        },
        {
          title: "溢价送驾",
          props: "floatingPrice",
        },
      ],
      titleName2: [
        {
          title: "发车时间",
          width: "120px",
          SpecialJudgment: (res, index) => {
            let timeStr = res.startDepartureTime;
            //定班只显示开始时间
            if (res.shiftType === 1) {
              timeStr += "-" + res.endDepartureTime;
            }
            return timeStr;
          },
        },
        {
          title: "班次类型",
          props: "",
          render: (h, params, index) => {
            return h("div", {
              domProps: {
                innerHTML: `${params.shiftType === 0
                  ? '<div><text style="width:40px;height:18px;line-height:18px;margin-right:8px;font-size:12px;color:#FFFFFF;background:#336FFE;padding:3px;border-radius:4px;display:inline-block;">定  班</text></div>'
                  : '<div><text style="width:40px;height:18px;line-height:18px;margin-right:8px;font-size:12px;color:#FFFFFF;background:#D40000;padding:3px;border-radius:4px;display:inline-block;">流水班</text></div>'
                  }`,
              },
            });
          },
        },
        {
          title: "车辆等级",
          props: "level",
          SpecialJudgment: (res) => {
            return this.levelList.find((t) => t.value == res).label;
          },
        },
        {
          title: "票位",
          props: "ticketNum",
        },
        {
          title: "在线选座",
          props: "onlineSeatStatus",
          SpecialJudgment: (res) => {
            return res ? "否" : "是";
          },
        },
        {
          title: "座位布局",
          props: "seatName",
          render: (h, params, index) => {
            return h("div", {
              style: {
                color: "#336ffe",
                cursor: "pointer",
              },
              domProps: {
                innerHTML: params.seatName,
              },
              on: {
                click: () => {
                  this.$refs.seatSelectionDialog.openDialog(
                    "查看座位布局",
                    params.seatPlanId
                  );
                },
              },
            });
          },
        },
        {
          title: "开班属性",
          props: "openShiftProperties",
          width: 200,
        },
        {
          title: "提示信息",
          props: "wayPoint",
          width: 100,
        },
        {
          title: "优惠票价",
          props: "discountTicketPrice",
        },
        {
          title: "优惠开始日期",
          props: "discountStartTime",
          width: "120",
          SpecialJudgment: (res) => {
            return res ? res.substr(0, 10) : "";
          },
        },
        {
          title: "优惠截止日期",
          props: "discountEndTime",
          width: "120",
          SpecialJudgment: (res) => {
            return res ? res.substr(0, 10) : "";
          },
        },
        {
          title: "状态",
          props: "status",
          render: (h, params, index) => {
            return h("div", {
              domProps: {
                innerHTML: `${params.status === 0
                  ? "<div>正常</div>"
                  : '<div style="color:#D40000;">停班</div>'
                  }`,
              },
            });
          },
        },
      ],
      levelList: [
        { value: 1, label: "小巴" },
        { value: 2, label: "中巴" },
        { value: 3, label: "大巴" },
      ],
      titleName: [
        {
          title: "线路名称",
          props: "name",
        },
        {
          title: "起点站",
          props: "startStation",
        },
        {
          title: "终点站",
          props: "endStation",
        },
        {
          title: "所属主线路",
          props: "mainLineName",
          width: 120,
        },
        {
          title: "所属企业",
          props: "companyName",
        },
        {
          title: "所属渠道",
          props: "channelName",
        },
        {
          title: "可售渠道",
          props: "vendibilityChannelName",
          SpecialJudgment: (res) => {
            return res ? res.join("，") : "";
          },
        },
        {
          title: "购票类型",
          props: "isRealName",
          SpecialJudgment: (res) => {
            return res == 0 ? "非实名" : "实名";
          },
        },
        {
          title: "预售天数",
          props: "preSellDay",
          SpecialJudgment: (res) => {
            return res || "默认";
          },
        },
        {
          title: "停售时间", // v7.1新增停售时间
          props: "stopSellingTime",
          SpecialJudgment: (res) => {
            return res || "默认";
          },
        },
        {
          title: "估计用时",
          props: "drivingTime",
        },
        {
          title: "核定价",
          props: "ticketPrice",
        },
        {
          title: "订单提醒号码",
          props: "phone",
          SpecialJudgment: (res) => {
            const list = res || [];
            return list.join(",");
          },
          width: 120,
        },
        {
          title: "客服电话",
          props: "customerServicePhone",
          SpecialJudgment: (res) => {
            const list = res || [];
            return list.join(",");
          },
        },
        {
          title: "微信二维码",
          props: "wxQrCode",
          width: 120,
          render: (h, params, index) => {
            if (params.wxQrCode) {
              return h("i", {
                class: "iconfont its_gy_image",
                style: {
                  "font-size": "18px",
                  cursor: "pointer",
                  color: "#336FFE",
                },
                on: {
                  click: () => {
                    this.imgPopup = true;
                    this.imgPopupImageUrl = host.imgURL + params.wxQrCode;
                  },
                },
              });
            } else {
              return "";
            }
          },
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            if (this.listFind("启禁用线路")) {
              return h("el-switch", {
                props: {
                  value: this.statusArray[index],
                  "active-color": "#13ce66",
                  "inactive-color": "#E3E3E3",
                },
                on: {
                  change: (val) => {
                    // this.$set(this.statusArray, index, val);
                    lineDisableAPI({
                      id: params.id,
                      status: !params.status,
                    }).then((res) => {
                      if (res.code == "SUCCESS") {
                        this.renderData();
                      }
                    });
                  },
                },
              });
            } else {
              return false;
            }
          },
        },
      ],
      statusArray: [],
      operationModeSelect: [
        { value: 0, label: "专车运营模式" },
        { value: 1, label: "混合运营模式" },
      ],
      statusSelect: [
        { value: 0, label: "是" },
        { value: 1, label: "否" },
      ],
      value: true,
      startSelect: [],
      endSelect: [],
      companyList: [],
      valueAddName: null,
      valueAdd: null,
      participateValueAddName: null,
      participateValueAdd: null,
      formData: {
        status: "",
        operationMode: undefined,
        startStationList: [],
        endStationList: [],
        companyIds: "",
        currentPage: 1,
        pageSize: 10,
        mainLineCode: "",
      },
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      childrenList: [],
      operationModeList: [
        { value: 0, label: "专车模式" },
        // { value: 1, label: "混合模式" }
      ],
      rules: {
        customerPhone: [
          {
            required: true,
            validator: this.customerPhoneFun,
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            validator: this.phoneRuleFun,
            trigger: "blur",
          },
        ],
        isRealName: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        ticketPrice: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请输入必填项", trigger: "change" },
        ],
        areaMode: [
          { required: true, message: "请输入必填项", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入必填项", trigger: "blur" }],
        departureTime: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        ticketNum: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        level: [{ required: true, message: "请输入必填项", trigger: "change" }],
        startStation: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        endStation: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
        operationMode: [
          { required: true, message: "请输入必填项", trigger: "change" },
        ],
        drivingTime: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (/^[1-9][0-9]*[0-9]*$/.test(value)) callback();
              else callback("请输入正整数");
            },
          },
        ],
        companyId: [
          { required: true, message: "请选择所属公司", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择车次类型", trigger: "blur" }],
        pickUpTime: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              let flag = this.addForm2.areaMode === 0;
              //接驾时间不能填相同的,且不能与自身相比较
              let times = [];              
              this.upData.map((item,index) => {
                if(index != this.rowIndex) times.push(item.pickUpTime);
              })
              let isRepeatTime = times.includes(Number(value));
              if (value === "") {
                callback("请填写接驾时间");
              } else if (
                flag ? !/^-?[1-9]\d*$/.test(value) : !/^-?[0-9]\d*$/.test(value)
              ) {
                //区域不可设置接驾时间区间是0，禁掉提示
                //再次迭代：不可填小数
                callback(flag ? "请填写非0整数" : "请填写整数");
              } else if (isRepeatTime) {
                callback("上车点存在相同的接驾时间");
              }
              else {
                callback();
              }
            },
          },
        ],
        floatingPrice: [
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              console.log(value);
              if (/^[0-9]\d*$/.test(value) || value == null || value == "") {
                callback();
              } else {
                callback("请填写非负整数");
              }
            },
          },
        ],
        mainLineCode: [
          { required: true, message: "请选择所属线路", trigger: "change" },
        ],
        lineDirection: [
          { required: true, message: "请选择线路方向", trigger: "change" },
        ],
        channelNo: [
          { required: true, message: "请选择所属渠道", trigger: "change" },
        ],
        vendibilityChannelNo: [
          { required: true, message: "请选择可售渠道", trigger: "change" },
        ],
        onlineSeatStatus: [
          { required: true, message: "请选择选座状态", trigger: "change" },
        ],
        seatPlanId: [
          { required: true, message: "请选择座位布局", trigger: "change" },
        ],
        wxQrCode: [
          {
            validator: this.imgRules,
            trigger: "blur",
          },
        ],
        shiftType: [
          { required: true, message: "请选择班次类型", trigger: "change" },
        ],
        startDepartureTime: [
          { required: true, message: "请输入必填项", trigger: "blur" },
          {
            validator: validateTimeForward,
            trigger: "change",
          },
        ],
        endDepartureTime: [
          { required: true, message: "请输入必填项", trigger: "blur" },
          {
            validator: validateTimeBackward,
            trigger: "change",
          },
        ],
      },
      rulesCopy: {
        startDepartureTime: [
          { required: true, message: "请输入必填项", trigger: "blur" },
          {
            validator: validateTimeForwardCopy,
            trigger: "change",
          },
        ],
        endDepartureTime: [
          { required: true, message: "请输入必填项", trigger: "blur" },
          {
            validator: validateTimeBackwardCopy,
            trigger: "change",
          },
        ],
      },
      statusList: [
        { value: 0, label: "正常" },
        { value: 1, label: "停班" },
      ],
      addForm: {
        ticketPrice: "",
        status: true,
        name: "",
        startStation: "",
        endStation: "",
        operationMode: "",
        drivingTime: "",
        companyId: [],
        phone: "",
        mainLineCode: "",
        lineDirection: null,
        channelNo: "", // 所属渠道
        vendibilityChannelNo: [], //可售渠道集合
        wxQrCode: "",
        wechatPayGoodsId: "",
        accountBinding: true,
        participateOperateCompanyIds: [], //参营公司集合
        notice:"",//消息通知
      },
      locationDisabled: false,
      locationType: 1, // 上车点位置类型变量，默认1，1-区域绘制，2-点位获取
      mainLineList: null, //所属主线路下拉框数据列表
      toConfigShow: false, // 配置弹出框显示变量
      toConfigData: null,
      bottomTableHeight: null,
      channelList: [], // 所属渠道下拉框数据
      vendibilityList: [], // 可售渠道下拉框数据
      imgBase64: "",
      imgMsg: "",
      imgSize: 0,
      fileList: [],
      wxQrCode: "wxQrCode",
      seatLayoutList: [], // 座位布局下拉框
      selectLoading: false,
      seatPlanIdProp: "",
      ticketNumTime: null,
      seatPlanIdWatch: false,
      pickerOptions: {
        disabledDate: (time) => {
          let disable = false;
          let arr = this.deepClone(this.shiftTableData);
          arr.forEach((item) => {
            item.endYearMonthDay = new Date(item.endYearMonthDay).getTime();
            item.startYearMonthDay = new Date(item.startYearMonthDay).getTime();
            disable =
              disable ||
              (time.getTime() > item.startYearMonthDay - 24 * 3600 * 1000 &&
                time.getTime() < item.endYearMonthDay);
          });
          return disable;
        },
      },
      queryLineID: null,
      attributeValueShow: false,
      //途经点
      passPointData: [],
      region: null,
      passPointtitleName: [
        {
          title: "途经点名称",
          props: "stationName",
        },
        {
          title: "显示名称",
          props: "stationAlias",
        },
        {
          title: "类型",
          props: "stationType",
          SpecialJudgment: (res) => {
            return res == 0 ? "车站 " : res == 1 ? "站点" : "区域";
          },
        },
        {
          title: "距出发地估计用时",
          props: "timeEstimation",
        },

        {
          title: "距出发地票价(元)",
          props: "price",
        },
      ],
      passPointTitle: "新增",
      isDisabled: false,
      dialogVisiblePassPoint: false,
      addFormPassPoint: {
        stationId: null,
        stationAlias: "",
        stationType: null,
        timeEstimation: null,
        price: null,
        lineStationType: 2, //0-上车点 1-下车点 2-途经点
      },
      rulesPassPoint: {
        stationId: [{ required: true, message: "请选择", trigger: "change" }],
        stationAlias: [
          { required: true, message: "请填写别名", trigger: "blur" },
        ],
        stationType: [{ required: true, message: "请选择", trigger: "change" }],
        timeEstimation: [
          { required: true, message: "请填写时间", trigger: "blur" },
          {
            required: true,
            validator: checkPositiveInteger,
            trigger: "blur",
          },
        ],
        price: [
          { required: true, message: "请填写票价", trigger: "blur" },
          {
            required: true,
            validator: checkPositiveNumber,
            trigger: "blur",
          },
        ],
      },
      stationList: [], //站点列表
      area: 0,
      dotNum: 0,
      lineRulesShow: false,
      lineLabels: [],
      inputVisible: false,
      inputValue: "",
      dialogVisibleEntrustPop: false,
      addEntrustForm: {
        systemNo: null,
        authorizeRatio: null
      },
      rulesEntrust: {
        systemNo: [{ required: true, message: "请选择", trigger: "change" }],
        authorizeRatio: [
          { required: true, message: "请填写", trigger: "blur" },
          {
            required: true,
            validator: checkPercentNumber,
            trigger: "blur",
          },
        ]
      },
      dialogVisibleInputPhone: false,
      addFormInputPhone: {
        customerPhone: ""
      },
      rulesInputPhone: {
        customerPhone: [
          {
            required: true,
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tabHeight:"calc(100% - 340px)",
      tabHeightNo:"calc(100% - 146px)",
      
    };
  },
  created () {
    // 获取所属渠道下拉框数据
    getCurrentUserChannelInfoAPI()
      .then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data || [];
          this.vendibilityList = res.data || [];
          this.getCompanyList();
          if (!res.data.length) {
            this.$message.error("暂无权限，请联系管理员");
            this.emptyFlag = true;
            this.pageLoading = false;
            return;
          }
          this.pageLoading = false;
        }
      })
      .catch(() => {
        this.pageLoading = false;
        this.emptyText = "暂无权限，请联系管理员";
      });
  },
  mounted () {
    let adminA = "可售渠道";
    let adminB = "微信二维码";
    if (!this.listFind(adminA)) {
      this.titleName.splice(6, 1);
    }
    if (!this.listFind(adminB)) {
      this.titleName.splice(this.titleName.length - 2, 1);
    }
    if (!this.listFind("上车点溢价")) {
      this.uptitleName.splice(3, 1);
    }
    if (!this.listFind("下车点溢价")) {
      this.downtitleName.splice(2, 1);
    }
    // 获取所属主线路管理下拉框数据
    queryIntercityMainLineListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.mainLineList = res.data;
      }
    });
    this.selectdataAPIFun();
    this.renderData();
    this.$nextTick(() => {
      this.tabHeight = this.listFind('委托售票-禁止开放') ? "calc(100% - 340px)" : "calc(100% - 146px)";
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
    // 默认选中第一行的样式
    // const times = setTimeout(function() {
    //   document.querySelectorAll(".defaultTable table tbody tr")[0].click();
    //   clearTimeout(times);
    // }, 600);
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
  watch: {
    shiftAradio: {
      handler (val) {
        console.log(val);
        console.log(this.attributeValueShow);
        if (val == "1") {
          this.shiftCalendarShow = false;
          this.attributeList = [
            {
              id: 1,
              label: "天天班",
            },
            {
              id: 2,
              label: "按星期",
            },
          ];
          this.attributeValue = 1;
        } else {
          this.shiftCalendarShow = true;
          this.attributeList = [
            {
              id: 3,
              label: "按公历日期",
            },
            {
              id: 4,
              label: "按农历日期",
            },
          ];
          if (this.attributeValueShow) {
            this.attributeValueShow = false;
          } else {
            this.attributeValue = 3;
          }
        }
        this.shiftCheckedShow = false;
      },
    },
    locationType: {
      handler (val) {
        if (val == 1) {
          this.locationDisabled = false;
        } else {
          this.locationDisabled = true;
        }
      },
    },
    "addForm1.ticketNum": function (val) {
      if (this.seatPlanIdWatch) {
        this.seatPlanIdWatch = false;
      } else {
        this.addForm1.seatPlanId = "";
      }
      if (val) {
        ticketNumTime && clearTimeout(ticketNumTime);
        ticketNumTime = setTimeout(() => {
          this.seatLayoutDataFun(val);
          clearTimeout(ticketNumTime);
        }, 600);
      }
    },
  },
  methods: {
    dragFinsh(val,index){
      const data = {
        lineAreaId:val[index].id,
        lineId:val[index].lineId,
        areaType:1,//0-上车集合 1-下车集合
        serial:val[index].serial,//当前
        targetSerial:index+1,
      }
      areaAdjustmentSerial(data).then(res=>{
        this.getDownList();
      })
    },
    bluerInput (e, limit) {
      let reg = /^[0-9]*[1-9][0-9]*$/;
      if (!e) return;
      if (!reg.test(e)) {
        this.$message.error('请输入非0整数');
        return
      } else if (e > limit) {
        this.$message.error('请输入小于票位数的整数');
        return
      }
    },
    onDialogCloseEntrust () {
      this.addEntrustForm = {
        systemNo: null,
        authorizeRatio: null
      };
      this.dialogVisibleEntrustPop = false;
    },
    entrustSave () {
      this.$refs.addEntrustForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          const data = {
            lineId: this.toConfigData.id,
            systemNo: this.addEntrustForm.systemNo,
            authorizeRatio: Number(this.addEntrustForm.authorizeRatio),
            authorizeName: this.systemNoList.find((item) => item.no == this.addEntrustForm.systemNo)?.desc,
            status: 0,//状态：0-正常 1-禁用
          }
          if (this.addEntrustForm.id) {
            //编辑
            data.id = this.addEntrustForm.id;
          }
          addOrUpdateLineAuthorizeConfig(data).then(res => {
            if (res.code === "SUCCESS") {
              this.getlineAuthorizeConfigData(data.lineId);//获取委托售票配置
              this.onDialogCloseEntrust();
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          })
        }
      })
    },
    //获取授权系统枚举
    getSystemNoListData (id) {
      getSystemNoList({ lineId: id }).then(res => {
        if (res.code === "SUCCESS") {
          this.systemNoList = res.data || [];
        }
      })
    },
    onAddEntrustPop (row) {
      if (row && row.id) {
        this.addEntrustForm = JSON.parse(JSON.stringify(row));
      }
      this.dialogVisibleEntrustPop = true;
    },
    entrustdeletePop (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h(
            "span",
            null,
            "请先暂停委托并手动执行定时任务后再删除。"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        deleteLineAuthorizeConfig({ id: row.id }).then(res => {
          if (res.code === "SUCCESS") {
            this.$message.success("删除成功！");
            this.getlineAuthorizeConfigData(row.lineId);//获取委托售票配置
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        })
      }).catch(() => { })
    },
    entrustupdatePop (row) {
      const data = { ...row };
      if (row.status === 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h(
              "span",
              null,
              "暂停委托后，授权的平台查询当前线路的排班信息为空。"
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            data.status = 1;
            addOrUpdateLineAuthorizeConfig(data).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("暂停委托成功");
                this.getlineAuthorizeConfigData(data.lineId);//获取委托售票配置
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消暂停",
            });
          });
      } else {
        data.status = 0;
        addOrUpdateLineAuthorizeConfig(data).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("启用成功");
            this.getlineAuthorizeConfigData(data.lineId);//获取委托售票配置
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onSaveInputPhone () {
      this.$refs.addFormInputPhone.validate((valid)=>{
        if(valid){
          if(this.addForm.notice){
            this.addForm.notice += `{${this.addFormInputPhone.customerPhone}}`;
          }else{
            this.addForm.notice = `{${this.addFormInputPhone.customerPhone}}`;
          }          
          this.onDialogCloseInputPhone();
        }
      })
    },
    onDialogCloseInputPhone () {    
      this.addFormInputPhone = {
        customerPhone:""
      };
      this.dialogVisibleInputPhone = false;
    },
    addPhoneNumberLineFun () {
      this.dialogVisibleInputPhone = true;
    },
    limitLength (event) {
      if (this.inputValue.length > 8) {
        this.inputValue = this.inputValue.slice(0, 8);
        event.target.value = this.inputValue; // 更新输入框的实际显示值
      }
    },
    handleClose (tag) {
      this.lineLabels.splice(this.lineLabels.indexOf(tag), 1);
    },
    handleInputConfirm () {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.lineLabels.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput () {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    //线路规则-模拟二级页面
    lineRulesFun (row) {
      this.toConfigData = row;
      this.lineRulesShow = true;
    },
    //获取委托售票配置
    getlineAuthorizeConfigData (id) {
      lineAuthorizeConfigByLineId({ lineId: id }).then(res => {
        if (res.code === "SUCCESS") {
          this.tableData0 = res.data || [];
        }
      })
    },
    //途经点选择change事件
    passStationChange(e){
      if(e){
       let obj = this.stationList.filter(item=>item.id === e);
       this.addFormPassPoint.stationType = obj[0]?.type;//0:车站 1:站点 2:区域
      }else{
        this.addFormPassPoint.stationType = null;
      }
    },
    //保存途经点新增
    onSavePassPoint () {
      this.$refs.addFormPassPoint.validate((va) => {
        if (va) {
          if (this.addFormPassPoint.id) {
            updateIntercityLineStationRelation(this.addFormPassPoint).then(
              (res) => {
                if (res.code === "SUCCESS") {
                  this.$message.success("更新成功");
                  this.onDialogClosePassPoint();
                  this.getDataList();
                }
              }
            );
          } else {
            const data = { lineId: this.lineData.id, ...this.addFormPassPoint };
            saveIntercityLineStationRelation(data).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("新增成功");
                this.onDialogClosePassPoint();
                this.getDataList();
              }
            });
          }
        }
      });
    },
    onDialogClosePassPoint () {
      this.addFormPassPoint = this.$options.data().addFormPassPoint;
      this.$refs.addFormPassPoint.resetFields();
      this.dialogVisiblePassPoint = false;
    },
    onUpdate4 (row) {
      this.addFormPassPoint = JSON.parse(JSON.stringify(row));
      this.onAdd4("编辑");
    },
    //更新途经点
    onDisable4 (row) {
      let flag = row.lineStationStatus === 0;
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, `确定${flag ? "禁用" : "启用"}途经点`),
          h("span", null, " ？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          updateIntercityLineStationRelation({
            id: row.id,
            lineStationStatus: flag ? 1 : 0,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success(flag ? "禁用成功" : "启用成功");
              row.lineStationStatus = flag ? 1 : 0;
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消",
          });
        });
    },
    //删除途经点
    onDelete4 (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除"),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteMidwayStationById({ id: row.id }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 复制车次保存
    copyCommit () {
      this.$refs.copyForm.validate((valid) => {
        if (valid) {
          cloneTrainAPI({
            id: this.copyID,
            shiftType: this.copyShiftType,
            // name: this.copyForm.name,
            startDepartureTime: this.copyForm.startDepartureTime,
            endDepartureTime: this.copyForm.endDepartureTime,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.copyPopup = false;
              this.copyForm = {
                // name: "",
                startDepartureTime: "",
                endDepartureTime: "",
              };
              this.getDataList();
            }
          });
        }
      });
    },
    copyPopupClose () { },
    // 打开复制车次弹窗
    copyTrainFun (val) {
      console.log(val);
      this.copyID = val.id;
      this.copyShiftType = val.shiftType;
      this.copyPopup = true;
      if (val.openShiftProperties) {
        this.copyTextarea = val.openShiftProperties;
        this.copyisShow = true;
      } else {
        this.copyisShow = false;
      }
    },
    handleStartCopyTime () {
      if (this.copyShiftType === 0) {
        this.copyForm.endDepartureTime = this.copyForm.startDepartureTime;
      } else {
        this.copyForm.endDepartureTime = "";
      }
    },
    shiftRegulationTypeFun () {
      if (this.attributeValue == 1) {
        return "DAY_DAY_SHIFT_TYPE";
      } else if (this.attributeValue == 2) {
        return "WEEK_SHIFT_TYPE";
      } else if (this.attributeValue == 3) {
        return "GREGORIAN_CALENDAR_SHIFT_TYPE";
      } else if (this.attributeValue == 4) {
        return "LUNAR_CALENDAR_SHIFT_TYPE";
      } else {
        return "";
      }
    },
    shiftRegulationReverseFun (val) {
      if (val == "LONG_TERM_START_SHIFT_TYPE") {
        return 1;
      } else if (val == "STAGE_OPEN_SHIFT_START_TYPE") {
        this.shiftStageShow = true;
        return 2;
      } else if (val == "LONG_TERM_STOP_SHIFT_TYPE") {
        return 3;
      } else if (val == "STAGE_OPEN_SHIFT_STOP_TYPE") {
        this.shiftStageShow = true;
        return 4;
      } else {
        return "";
      }
    },
    shiftRegulationTypeReverseFun (val) {
      if (val == "DAY_DAY_SHIFT_TYPE") {
        return 1;
      } else if (val == "WEEK_SHIFT_TYPE") {
        return 2;
      } else if (val == "GREGORIAN_CALENDAR_SHIFT_TYPE") {
        return 3;
      } else if (val == "LUNAR_CALENDAR_SHIFT_TYPE") {
        return 4;
      } else {
        return "";
      }
    },
    // 点击开班属性
    shiftBtnFun (val) {
      this.shiftID = val.id;
      this.shiftPopupShow = true;
      if (val.openShiftProperties) {
        queryVehicleShiftRegulationInfoAPI({
          id: val.id,
        }).then((res) => {
          if (res.code == "SUCCESS") {
            this.shiftDatePickerValue = res.data.basisTime;
            this.shiftListValue = this.shiftRegulationReverseFun(
              res.data.openShiftRegulationType
            );
            this.shiftCourseFun(this.shiftListValue);
            if (this.shiftListValue == 2 || this.shiftListValue == 4) {
              this.shiftTableData =
                res.data.openShiftRegulationData.stageYearMonthDays;
            }
            this.attributeValue = this.shiftRegulationTypeReverseFun(
              res.data.shiftRegulationType
            );
            console.log("thisss", this.attributeValue);
            console.log("thisss", this.attributeValue == 1);
            if (this.attributeValue == 1 || this.attributeValue == 2) {
              this.shiftAradio = "1";
              if (this.attributeValue == 2) {
                this.shiftCheckedShow = true;
                this.shiftAttributeCheckedValue =
                  res.data.shiftRegulationData.data;
                this.shiftAttributeCheckedValue =
                  this.shiftAttributeCheckedValueFun(false);
              } else {
                this.shiftCheckedShow = false;
                this.shiftAttributeCheckedValue = [];
              }
            } else {
              this.attributeValueShow = true;
              this.calendarArray = res.data.shiftRegulationData.data;
              this.shiftAradio = "2";
              // this.shiftCalendarShow = false;
              // this.$nextTick(() => {
              //   this.shiftCalendarShow = true;
              // });
            }
          }
        });
      }
    },
    // 开班属性确认回调
    confirmFun () {
      if (!this.shiftDatePickerValue) {
        this.$message.error("请选择基准日期");
        return;
      }
      if (!this.shiftListValue) {
        this.$message.error("请选择开班属性");
        return;
      }
      if (this.shiftAradio == "1" && this.attributeValue == 2) {
        if (this.shiftAttributeCheckedValue.length == 0) {
          this.$message.error("按星期排班时至少选择一天");
          return;
        }
      }
      if (this.shiftAradio == "2") {
        if (this.calendarArray.length == 0) {
          this.$message.error("按日历排班时至少选择一天");
          return;
        }
      }
      if (this.shiftTableData.length == 0 && this.shiftListValue == 2) {
        this.$message.error("请填写开班时间段");
        return;
      }
      if (this.shiftTableData.length == 0 && this.shiftListValue == 4) {
        this.$message.error("请填写停班时间段");
        return;
      }
      /**
       * LONG_TERM_START_SHIFT_TYPE 长期开班类型
       * STAGE_OPEN_SHIFT_START_TYPE 阶段开班类型
       * LONG_TERM_STOP_SHIFT_TYPE 长期停班类型
       * STAGE_OPEN_SHIFT_STOP_TYPE 阶段停班类型
       *
       *
       * DAY_DAY_SHIFT_TYPE 天天排班
       * WEEK_SHIFT_TYPE 按星期排班
       * GREGORIAN_CALENDAR_SHIFT_TYPE 按公历日期排班
       * LUNAR_CALENDAR_SHIFT_TYPE 按农历日期排班
       * SPECIFIC_DATE_SHIFT_TYPE 特定日期排班类型
       *
       */
      let shiftOBJ = {
        id: this.shiftID,
        basisTime: this.GMTToStr(this.shiftDatePickerValue),
        openShiftRegulationType:
          this.shiftListValue == 1
            ? "LONG_TERM_START_SHIFT_TYPE"
            : this.shiftListValue == 2
              ? "STAGE_OPEN_SHIFT_START_TYPE"
              : this.shiftListValue == 3
                ? "LONG_TERM_STOP_SHIFT_TYPE"
                : this.shiftListValue == 4
                  ? "STAGE_OPEN_SHIFT_STOP_TYPE"
                  : "",
        openShiftRegulationData: this.openShiftRegulationDataFun(),
        shiftRegulationType: this.shiftRegulationTypeFun(),
        shiftRegulationData: {
          shiftType: this.shiftRegulationTypeFun(),
          data:
            this.attributeValue == 2
              ? this.shiftAttributeCheckedValueFun()
              : this.attributeValue == 3 || this.attributeValue == 4
                ? this.calendarArray
                : [],
        },
      };
      // 更新开班属性接口调用
      updateVehicleShiftRegulationAPI(shiftOBJ).then((res) => {
        if (res.code == "SUCCESS") {
          this.clearForm(false);
          this.handleNodeClick();
        }
      });
    },
    shiftAttributeCheckedValueFun (onOff = true) {
      let ary = [];
      if (onOff) {
        this.shiftAttributeCheckedValue.map((item) => {
          if (item == "星期一") {
            ary.push(2);
          } else if (item == "星期二") {
            ary.push(3);
          } else if (item == "星期三") {
            ary.push(4);
          } else if (item == "星期四") {
            ary.push(5);
          } else if (item == "星期五") {
            ary.push(6);
          } else if (item == "星期六") {
            ary.push(7);
          } else if (item == "星期天") {
            ary.push(1);
          }
        });
      } else {
        this.shiftAttributeCheckedValue.map((item) => {
          if (item == 2) {
            ary.push("星期一");
          } else if (item == 3) {
            ary.push("星期二");
          } else if (item == 4) {
            ary.push("星期三");
          } else if (item == 5) {
            ary.push("星期四");
          } else if (item == 6) {
            ary.push("星期五");
          } else if (item == 7) {
            ary.push("星期六");
          } else if (item == 1) {
            ary.push("星期天");
          }
        });
      }
      return ary;
    },
    openShiftRegulationDataFun () {
      if (this.shiftListValue == 2 || this.shiftListValue == 4) {
        return {
          stageYearMonthDays: this.shiftTableData,
        };
      } else {
        return { stageYearMonthDays: [] };
      }
    },
    // 开班属性点击添加按钮添加时间
    shiftAddDateFun () {
      if (this.shiftAddDateValue) {
        this.shiftTableData.push({
          startYearMonthDay: this.GMTToStr(this.shiftAddDateValue[0]),
          endYearMonthDay: this.GMTToStr(this.shiftAddDateValue[1]),
        });
        this.shiftAddDateValue = "";
      }
    },
    // 关闭开班属性弹窗
    clearForm (val) {
      this.shiftPopupShow = val;
      this.shiftDatePickerValue = "";
      this.shiftListValue = null;
      this.shiftAradio = "1";
      this.shiftStageShow = false;
      this.attributeList = [
        {
          id: 1,
          label: "天天班",
        },
        {
          id: 2,
          label: "按星期",
        },
      ];
      this.shiftTableData = [];
      this.calendarArray = [];
    },
    shiftDeleteFun (val) {
      this.shiftTableData.splice(val.$index, 1);
    },
    calendarClassFun (val) {
      if (this.calendarArray.indexOf(val) == -1) {
        return "";
      } else {
        return "calendarActive";
      }
    },
    calendarFun (val) {
      if (this.calendarArray.indexOf(val) == -1) {
        this.calendarArray.push(val);
      } else {
        this.calendarArray.splice(this.calendarArray.indexOf(val), 1);
      }
    },
    // 排版设置里面的下拉框change事件
    shiftAttributeChange (val) {
      this.shiftCheckedShow = false;
      this.shiftAttributeCheckedValue = [];
      if (val == 2) {
        this.shiftCheckedShow = true;
      } else if (val == 3 || val == 4) {
        this.calendarArray = [];
        if (val == 3) {
          this.calendarNum = 31;
        } else {
          this.calendarNum = 30;
        }
      }
    },
    // 开班属性下拉框change方法
    shiftCourseFun (val) {
      // 长期开班
      if (val == 1) {
        this.shiftCourseLabel =
          "从基准日期之后，将会长期开班，直到开班设置发生改变";
        this.shiftStageShow = false;
        this.shiftTableData = [];
        this.shiftAddDateValue = "";
      }
      //  阶段开班
      else if (val == 2) {
        this.shiftCourseLabel = "阶段开班，在设定时间段之外默认为停班";
        this.shiftStageShow = true;
      }
      // 长期停班
      else if (val == 3) {
        this.shiftCourseLabel =
          "从基准日期之后，将会长期停班，直到开班设置发生改变";
        this.shiftStageShow = false;
        this.shiftTableData = [];
        this.shiftAddDateValue = "";
      }
      // 阶段停班
      else if (val == 4) {
        this.shiftCourseLabel = "阶段停班，在设定时间段之外默认为开班";
        this.shiftStageShow = true;
      }
    },
    // 点击预览查看座位布局图
    seatPreviewFun () {
      if (this.addForm1.seatPlanId) {
        this.$refs.seatSelectionDialog.openDialog(
          "查看座位布局",
          this.addForm1.seatPlanId
        );
      }
    },
    // 模糊查询座位布局数据
    seatLayoutDataFun (val) {
      queryBySeatNameAPI({
        ticketNumber: val,
        channelNo: this.toConfigData.channelNo,
      }).then((res) => {
        this.seatLayoutList = res.data;
        this.selectLoading = false;
      });
    },
    // 在线选座下拉框change
    onlineSeatFun (val) {
      // 如果禁用
      if (val === 1) {
        this.addForm1.seatPlanId = "";
        this.addForm1.seatName = "";
        this.seatPlanIdProp = "";
      } else {
        this.seatPlanIdProp = "seatPlanId";
      }
    },
    // 座位布局下拉框change方法
    seatChangeFun (val) {
      if (val !== "") {
        let ob = this.seatLayoutList.find((value) => value["id"] == val);
        this.addForm1.seatName = ob.seatName;
      }
    },
    // 保存前上传图片方法
    addImgFun () {
      // -----------------先上传图片-------------------
      if (this.fileList.length > 0) {
        // FormData 对象
        let form = new FormData();
        // 文件对象
        form.append("files", this.fileList[0].raw);
        new Promise((resolve, reject) => {
          uploadFileAPI(form).then((res) => {
            if (res.code == "SUCCESS") {
              resolve(res.data);
            }
          });
        }).then((jump) => {
          this.addForm.wxQrCode = jump[0];
          this.addOnSave();
        });
      } else {
        this.addOnSave();
      }
    },
    // 所属渠道下拉框change事件
    channelNoFun (val) {
      this.addForm.vendibilityChannelNo = [val];
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun (file, fileList) {
      this.fileList = [file];
      let that = this;
      this.imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.imgSize = file.size;
      var a = new FileReader();
      a.readAsDataURL(file.raw); //读取文件保存在result中
      a.onload = function (e) {
        that.imgBase64 = e.target.result; //读取的结果在result中
        that.$refs.addForm.validateField("wxQrCode");
      };
    },
    // 移除图片事件
    imageListRemove (file, fileList) {
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = "";
      this.fileList = [];
      this.$refs.addForm.clearValidate("wxQrCode");
    },
    // 点击表格配置按钮
    toConfigFun (scope) {
      this.tableData0 = [];
      this.channelNo = scope.channelNo;
      this.getSystemNoListData(scope.id);
      this.getlineAuthorizeConfigData(scope.id);//获取委托售票配置
      if(this.listFind('委托售票-禁止开放')){
        this.titleName2.splice(4,0,{
          title: "委托售票",
          props: "authorizeVehicleShiftIntercitys",
          width: 180,
          SpecialJudgment: res => {
            let text = "";
            res?.map(item => {
              let name = this.systemNoList.find((i) => i.no == item.systemNo)?.desc;
              text += (name + "：" + item.ticketNum + ";");
            })
            return text
          },
        },)
      };
      this.toConfigShow = true;
      this.toConfigData = scope;
      const data = {
        pageSize: 10,
        currentPage: 1,
        lineName: scope.mainLineName,
      };
      queryIntercityMainLinePageAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          const obj = res.data.list[0];
          const start =
            obj.startCityName.split(",")[obj.startCityCodes.length - 1];
          const end = obj.endCityName.split(",")[obj.endCityCodes.length - 1];
          this.region = [start, end];
        }
      });
      this.ticketPriceAll = scope.ticketPrice;
      this.handleNodeClick(scope);
    },
    // 点击配置弹出框的返回按钮
    returnList () {
      this.toConfigShow = false;
    },
    // 拖拽定位回传
    positionVariableFun (val, name, detailName) {
      this.positionVariable = val;
      if (name) {
        this.addForm2.name = name;
        this.addForm3.name = name;
      }
      if (detailName) {
        this.addForm2.detialAddress = detailName;
        this.addForm3.detialAddress = detailName;
        this.addForm2.detialAddressName = detailName;
        this.addForm3.detialAddressName = detailName;
      }
    },
    // 位置类型change事件
    locationChangeFun (val) {
      this.addForm2.name = "";
      this.addForm3.name = "";
      this.$set(this.addForm2, "pickUpTime", null);
      // 如果是0，就是区域绘制
      if (val == 0) {
        this.locationType = 1;
      } else {
        this.locationType = 2;
      }
    },
    onDelete2 (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除"),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteArea({ id: row.id, areaType: 0 }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    onDelete3 (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除"),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteArea({ id: row.id, areaType: 0 }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    onDelete1 (row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除"),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteShift({ id: row.id }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.getDataList();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 删除
    onDelete (scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [
          h("span", null, "您将删除"),
          h("span", null, "，是否删除？"),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteLine({ id: scope.id }).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message({
                type: "success",
                showClose: true,
                message: "删除成功!",
              });
              this.formData = {
                status: "",
                operationMode: undefined,
                startStationList: [],
                endStationList: [],
                companyIds: "",
                currentPage: 1,
                pageSize: 10,
                mainLineCode: "",
              };
              this.renderData();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 点击表格查看按钮--删除查看按钮，这个方法只有编辑会调用
    onUpdate (scope, val) {
      this.editDisabled = true;
      this.title = "修改";
      this.phoneNumberArray = JSON.parse(JSON.stringify(scope.phone)) || [""];
      this.customerServicePhone = JSON.parse(
        JSON.stringify(scope.customerServicePhone)
      ) || [""];
      const that = this;
      this.fileList = scope.wxQrCode
        ? [{ url: host.imgURL + scope.wxQrCode }]
        : [];

      queryLineList({ id: scope.id }).then((res) => {
        if (res.code === "SUCCESS") {
          const {
            companyName,
            companyId,
            participateOperateCompanyIds,
            participateOperateCompanyNames,
          } = res.data;
          this.lineData = res.data;
          this.addForm = {...this.addForm,...res.data};
          this.lineLabels = res.data.lineLabels;
          this.seeShow = true;
          this.queryChildrenList2();
          this.addForm.status = scope.status === 0;
          this.$nextTick(() => {
            that.valueAddName = companyName;
            that.valueAdd = companyId;
            //参营公司回显
            that.participateValueAddName =
              participateOperateCompanyNames?.join();
            that.participateValueAdd = participateOperateCompanyIds;
            this.$refs.companySelectOpare.initHandleMultiple(
              that.participateValueAddName,
              that.participateValueAdd
            );
          });
        }
      });
      that.dialogVisible = true;
    },
    // 新增/保存
    onSave () {
      this.addImgFun();
    },
    addOnSave () {
      let objs = {};
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true;
          this.addForm.phone = this.phoneNumberArray;
          this.addForm.customerServicePhone = this.customerServicePhone;
          objs = this.deepClone(this.addForm);          
          objs.notice = this.addForm.notice ? this.addForm.notice : null;
          if (this.addForm.status) {
            objs.status = 0;
          } else {
            objs.status = 1;
          }
          objs.lineLabels = this.lineLabels;
          if (this.title.indexOf("新增") !== -1) {
            insertLine(objs)
              .then((res) => {
                if (res.code === "SUCCESS") {
                  this.$message.success("新增成功");
                  this.onDialogClose();
                  this.formData = {
                    status: "",
                    operationMode: undefined,
                    startStationList: [],
                    endStationList: [],
                    companyIds: "",
                    mainLineCode: "",
                    currentPage: 1,
                    pageSize: 10,
                  };
                  this.renderData();
                  //刷新一下站点数据
                  this.selectdataAPIFun();
                } else {
                  this.$message.error(res.msg);
                  this.editLoading = false;
                }
              })
              .catch(() => {
                this.editLoading = false;
              });
          } else {
            updateLine(objs)
              .then((res) => {
                if (res.code === "SUCCESS") {
                  this.$message.success("修改成功");
                  this.onDialogClose();
                  this.renderData();
                  this.selectdataAPIFun();
                } else {
                  this.$message.error(res.msg);
                  this.editLoading = false;
                }
              })
              .catch(() => {
                this.editLoading = false;
              });
          }
        }
      });
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.lineLabels = [];
      this.value = null;
      this.valueAdd = null;
      this.valueAddName = "";
      this.phoneNumberArray = [""];
      this.customerServicePhone = [""];
      this.addForm = {
        id: "",
        phone: "",
        userName: "",
        idCardType: "",
        idCardNo: "",
        address: "",
        companyId: "",
        driveType: "",
        channelNo: "", // 所属渠道
        vendibilityChannelNo: [], //可售渠道集合
        wxQrCode: "",
        status: true,
        accountBinding: true,
        notice:""
      };
      this.$refs.addForm.clearValidate();
      this.dialogVisible = false;
      this.editLoading = false;
      this.fileList = [];
      this.$refs.companySelect.clearHandle();
      this.$refs.companySelectOpare.clearHandle();
    },
    // 新增线路
    onAdd () {
      // 获取所属渠道下拉框数据
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data || [];
          this.vendibilityList = res.data || [];
          if (res.data.length === 1) {
            this.addForm.channelNo = res.data[0].channelNo;
            this.addForm.vendibilityChannelNo.push(res.data[0].channelNo);
          }
        }
      });
      this.editDisabled = false;
      this.title = "新增";
      this.seeShow = true;
      this.valueAdd = null;
      this.valueAddName = "";
      if (this.$refs.addForm) this.$refs.addForm.clearValidate();
      this.addForm = { ...this.addForm, companyId: null, isRealName: 0 };
      this.dialogVisible = true;
    },
    // 计算表格高度
    computeHeight () {
      if (this.$refs["elTab"]) {
        const TabHeight = this.$refs["elTab"].$el.clientHeight;
        this.bottomTableHeight = this.listFind('委托售票-禁止开放') ? (TabHeight - 120) : TabHeight;
      }
      const wholeHeight = this.$refs.lineManagement.clientHeight;
      this.tableHeight = wholeHeight - 220;
    },
    queryChildrenList (query) {
      this.childrenList = [];
      if (query != "") {
        setTimeout(() => {
          lineListTrip({
            keyWord: query,
            companyId: this.addForm.companyId,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.childrenList = res.data?.filter(
                (e) => e.name != this.addForm.name
              );
            }
          });
        }, 200);
      } else {
        this.queryChildrenList2();
      }
    },
    queryChildrenList2 () {
      lineListTrip({
        keyWord: null,
        companyId: this.addForm.companyId,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.childrenList = res.data.filter(
            (e) => e.name != this.addForm.name
          );
        }
      });
    },
    form1StartTime (val) {
      if (val == null) {
        this.addForm1.discountStartTime = "";
      }
    },
    form2StartTime (val) {
      if (val == null) {
        this.addForm1.discountEndTime = "";
      }
    },
    DISCOUNTFUN () {
      // 如果没有填写优惠票价
      if (!this.addForm1.discountTicketPrice) {
        this.addForm1.discountStartTime = "";
        this.addForm1.discountEndTime = "";
        return true;
      } else {
        // 反之如果填写了优惠票价，先判断是否小于当前班次的核定价
        if (this.addForm1.discountTicketPrice >= this.ticketPriceAll) {
          this.$message.error("优惠价不能大于核定价！");
          return false;
        } else {
          if (
            this.addForm1.discountStartTime &&
            this.addForm1.discountEndTime
          ) {
            // 判断时间
            if (
              new Date(
                this.GMTToStr(new Date(this.addForm1.discountStartTime)) +
                " 00:00:00"
              ).getTime() <
              new Date(
                this.GMTToStr(new Date(this.addForm1.discountEndTime)) +
                " 23:59:59"
              ).getTime()
            ) {
              this.addForm1.discountStartTime =
                this.GMTToStr(new Date(this.addForm1.discountStartTime)) +
                " 00:00:00";
              this.addForm1.discountEndTime =
                this.GMTToStr(new Date(this.addForm1.discountEndTime)) +
                " 23:59:59";
              return true;
            } else {
              this.$message.error("截止时间不得小于开始时间");
              return false;
            }
          } else {
            this.$message.error("请填写时间");
            return false;
          }
        }
      }
    },
    radioChange (value) {
      if (value === 0) {
        this.addForm1.endDepartureTime = this.addForm1.startDepartureTime;
      } else {
        this.addForm1.endDepartureTime = "";
      }
    },
    handleStartTime (value) {
      if (this.addForm1.shiftType === 0) {
        this.addForm1.endDepartureTime = this.addForm1.startDepartureTime;
      }
    },
    onSave1 () {
      this.$refs.addForm1.validate((valid) => {
        if (valid) {
          //单独在判断委托售票是否小于票位          
          if(this.addForm1.authorizeVehicleShiftIntercitys && this.addForm1.authorizeVehicleShiftIntercitys.length){
           let isMore = this.addForm1.authorizeVehicleShiftIntercitys.some((item)=>item.ticketNum > this.addForm1.ticketNum);
           if(isMore){
            this.$message.error('委托售票票数应小于票位数');
            return
           }
          }
          this.editLoading = true;
          if (this.title.indexOf("新增") !== -1) {
            if (this.DISCOUNTFUN() == true) {
              insertShift({ ...this.addForm1, channelNo: this.channelNo }).then(
                (res) => {
                  if (res.code === "SUCCESS") {
                    this.$message.success("新增成功");
                    this.getDataList();
                    this.onDialogClose1();
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              );
            }
          } else {
            if (this.DISCOUNTFUN() == true) {
              updateShift({ ...this.addForm1, channelNo: this.channelNo }).then(
                (res) => {
                  if (res.code === "SUCCESS") {
                    this.$message.success("修改成功");
                    this.getDataList();
                    this.onDialogClose1();
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              );
            }
          }
        }
      });
    },
    // 新增 or 修改 上车点保存按钮点击事件
    onSave2 () {
      this.$refs.addForm2.validate((valid) => {
        if (valid) {
          this.addForm2.areaContent = this.$refs.editmap.form.area;
          if (this.title == "新增") {
            let flag = this.upData.some(
              (res) => res.name == this.addForm2.name
            );
            if (flag) {
              this.$message.warning("已存在该位置名称！");
              return;
            }
          }
          // 如果locationType为1表示区域
          if (this.locationType == 1) {
            if (this.areaContentData) {
              this.addForm2.areaContent = this.areaContentData;
            }
            if (this.addForm2.areaContent) {
              this.editLoading = true;
              this.addForm2.area = this.area;
              // 区域新增接口调用
              if (this.title.indexOf("新增") !== -1) {
                insertArea(this.addForm2).then((res) => {
                  if (res.code === "SUCCESS") {
                    this.$message.success("新增成功");
                    this.onDialogClose2();
                    this.getDataList();
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              } else {
                updateArea(this.addForm2).then((res) => {
                  if (res.code === "SUCCESS") {
                    this.$message.success("修改成功");
                    this.onDialogClose2();
                    this.getDataList();
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              }
              this.editLoading = false;
            } else {
              this.$message.warning("请绘制区域");
            }
          } else if (this.locationType == 2) {
            // 如果为2表示固定点
            const ary = JSON.parse(this.positionVariable);
            this.addForm2.areaContent = JSON.stringify(ary);
            if (this.title.indexOf("新增") !== -1) {
              linePointAPI(this.addForm2).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("新增成功");
                  this.onDialogClose2();
                  this.getDataList();
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              updateLinePointAPI(this.addForm2).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("修改成功");
                  this.onDialogClose2();
                  this.getDataList();
                } else {
                  this.$message.error(res.msg);
                }
              });
            }
            this.editLoading = false;
          }
        }
      });
    },
    areaContentFUN () {
      this.areaContentData = null;
      this.addForm3.areaContent = "";
      this.addForm2.areaContent = "";
    },
    changeAreaContent (obj) {
      this.dotNum = obj.path.length;
      if (this.dotNum >= 160)
        this.$message.warning("请删除定位点数量至160以下");
      this.areaContentData = JSON.stringify(obj.path);
      this.area = parseInt(obj.area);
    },
    onSave3 () {
      this.$refs.addForm3.validate((valid) => {
        if (valid) {
          this.addForm3.areaContent = this.$refs.editmap.form.area;
          if (this.title == "新增") {
            let flag = this.downData.some(
              (res) => res.name == this.addForm3.name
            );
            if (flag) {
              this.$message.warning("已存在该位置名称！");
              return;
            }
          }
          if (this.locationType == 1) {
            if (this.areaContentData) {
              this.addForm3.areaContent = this.areaContentData;
            }
            if (this.addForm3.areaContent) {
              this.editLoading = true;
              this.addForm3.area = this.area;
              if (this.title.indexOf("新增") !== -1) {
                insertArea(this.addForm3).then((res) => {
                  if (res.code === "SUCCESS") {
                    this.$message.success("新增成功");
                    this.onDialogClose3();
                    this.getDataList();
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              } else {
                updateArea(this.addForm3).then((res) => {
                  if (res.code === "SUCCESS") {
                    this.$message.success("修改成功");
                    this.onDialogClose3();
                    this.getDataList();
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              }
              this.editLoading = false;
            } else {
              this.$message.warning("请绘制区域");
            }
          } else {
            const ary = JSON.parse(this.positionVariable);
            this.addForm3.areaContent = JSON.stringify(ary);
            if (this.title.indexOf("新增") !== -1) {
              linePointAPI(this.addForm3).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("新增成功");
                  this.onDialogClose3();
                  this.getDataList();
                } else {
                  this.$message.error(res.msg);
                }
              });
            } else {
              updateLinePointAPI(this.addForm3).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("修改成功");
                  this.onDialogClose3();
                  this.getDataList();
                } else {
                  this.$message.error(res.msg);
                }
              });
            }
            this.editLoading = false;
          }
        }
      });
    },
    // 弹窗关闭时的回调
    onDialogClose1 () {
      this.addForm1 = {
        lineId: "",
        name: "",
        startDepartureTime: "",
        endDepartureTime: "",
        level: "",
        ticketNum: "",
        status: "",
        type: "",
        discountTicketPrice: "",
        discountStartTime: "",
        discountEndTime: "",
        onlineSeatStatus: "", // 在线选座状态 0：启用  1：禁用
        seatPlanId: "",
        seatName: "",
        shiftType: 0,
        authorizeVehicleShiftIntercitys: [{ ticketNum: null }],
      };
      this.$refs.addForm1.clearValidate();
      this.dialogVisible1 = false;
      this.editLoading = false;
      this.seatPlanIdProp = "";
    },
    // 弹窗关闭时的回调
    onDialogClose2 () {
      this.addForm2 = {
        name: "",
        lineId: "",
        areaMode: "",
        areaType: 0,
        areaContent: "",
        status: "",
      };
      this.$refs.addForm2.clearValidate();
      this.dialogVisible2 = false;
      this.editLoading = false;
      this.locationType = 1;
      this.areaContentData = null;
    },
    // 弹窗关闭时的回调
    onDialogClose3 () {
      this.addForm3 = {
        name: "",
        lineId: "",
        areaMode: "",
        areaType: 1,
        areaContent: "",
        status: "",
      };
      this.$refs.addForm3.clearValidate();
      this.dialogVisible3 = false;
      this.editLoading = false;
      this.locationType = 1;
      this.areaContentData = null;
    },
    onUpdate1 (row) {
      this.seatPlanIdWatch = true;
      this.title = "修改";
      if (!row.onlineSeatStatus) this.seatPlanIdProp = "seatPlanId";
      this.addForm1 = JSON.parse(JSON.stringify(row));
      this.dialogVisible1 = true;
      this.CARdisabled = true;
    },
    // 上车点点击编辑事件
    onUpdate2 (row) {
      this.rowIndex = row.$index;
      this.title = "修改";
      this.addForm2 = JSON.parse(JSON.stringify(row));
      this.addForm2Copy = JSON.parse(JSON.stringify(row));
      if (this.addForm2.areaMode == 1) {
        this.locationType = 2;
      } else {
        this.locationType = 1;
      }
      // 判断修改下拉框
      // if (this.upData[0].areaMode == "0") {
      //   this.areaModeList = [{ value: 0, label: "区域" }];
      //   this.locationType = 1;
      //   this.addForm2.areaMode = 0;
      // } else {
      //   this.areaModeList = [{ value: 1, label: "固定点" }];
      //   this.locationType = 2;
      //   this.addForm2.areaMode = 1;
      // }
      this.dialogVisible2 = true;
    },
    // 下车点点击编辑事件
    onUpdate3 (row) {
      this.title = "修改";
      this.addForm3 = JSON.parse(JSON.stringify(row));
      this.addForm2Copy = JSON.parse(JSON.stringify(row));
      if (this.addForm3.areaMode == 1) {
        this.locationType = 2;
      } else {
        this.locationType = 1;
      }
      // 判断修改下拉框
      // if (this.downData[0].areaMode == "0") {
      //   this.areaModeList = [{ value: 0, label: "区域" }];
      //   this.locationType = 1;
      //   this.addForm3.areaMode = 0;
      // } else {
      //   this.areaModeList = [{ value: 1, label: "固定点" }];
      //   this.locationType = 2;
      //   this.addForm3.areaMode = 1;
      // }
      this.dialogVisible3 = true;
    },
    rowClickFun (row) {
      this.ticketPriceAll = row.ticketPrice;
      this.handleNodeClick(row);
    },
    // 查询线路详情
    handleNodeClick (node) {
      node && (this.queryLineID = node.id);
      queryLineList({ id: node ? node.id : this.queryLineID }).then((res) => {
        if (res.code === "SUCCESS") {
          this.lineData = res.data;
          this.getDataList();
        }
      });
    },
    onDisable1 (row) {
      if (row.status === 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h(
              "span",
              null,
              "停班后，已经生成的班次变更为已停班，且未来将不再自动生成班次。已售票或预留的班次不受影响。"
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            disableShift({ id: row.id, status: 1 }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("停班成功");
                row.status = 1;
                this.getCompanyList();
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消停班",
            });
          });
      } else {
        disableShift({ id: row.id, status: 0 }).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("启用成功");
            row.status = 0;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onDisable2 (row) {
      if (row.status === 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h("span", null, "确定禁用上车点"),
            h("span", { style: "color: #059370" }, row.userName),
            h("span", null, " ？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            disableArea({ id: row.id, status: 1, areaType: 0 }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("禁用成功");
                row.status = 1;
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消禁用",
            });
          });
      } else {
        disableArea({ id: row.id, status: 0, areaType: 0 }).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("启用成功");
            row.status = 0;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onDisable3 (row) {
      if (row.status === 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "消息",
          type: "warning",
          message: h("p", null, [
            h("span", null, "确定禁用下车点"),
            h("span", { style: "color: #059370" }, row.userName),
            h("span", null, " ？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            disableArea({ id: row.id, status: 1, areaType: 1 }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("禁用成功");
                row.status = 1;
              } else {
                this.$message.error(res.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              showClose: true,
              message: "已取消禁用",
            });
          });
      } else {
        disableArea({ id: row.id, status: 0, areaType: 1 }).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("启用成功");
            row.status = 0;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    // 新增
    onAdd1 () {
      this.CARdisabled = false;
      if (this.lineData != undefined) {
        this.title = "新增";
        if (this.$refs.addForm1) this.$refs.addForm1.clearValidate();
        this.addForm1.lineId = this.lineData.id;
        this.dialogVisible1 = true;
      }
    },
    // 新增
    onAdd2 () {
      if (this.lineData != undefined) {
        this.title = "新增";
        if (this.$refs.addForm2) this.$refs.addForm2.clearValidate();
        this.addForm2 = {
          name: "",
          lineId: "",
          areaMode: 0,
          areaType: 0,
          areaContent: "",
          status: "",
        };
        this.addForm2.lineId = this.lineData.id;
        this.dialogVisible2 = true;
      }
      // // 如果上车点有数据
      // if (this.upData.length > 0) {
      //   // 0区域  1固定点
      //   if (this.upData[0].areaMode == "0") {
      //     this.areaModeList = [{ value: 0, label: "区域" }];
      //     this.locationType = 1;
      //     this.addForm2.areaMode = 0;
      //   } else {
      //     this.areaModeList = [{ value: 1, label: "固定点" }];
      //     this.locationType = 2;
      //     this.addForm2.areaMode = 1;
      //   }
      // } else {
      //   this.areaModeList = [
      //     { value: 0, label: "区域" },
      //     { value: 1, label: "固定点" },
      //   ];
      //   this.addForm2.areaMode = 0;
      // }
    },
    // 新增
    onAdd3 () {
      if (this.lineData != undefined) {
        this.title = "新增";
        if (this.$refs.addForm3) this.$refs.addForm3.clearValidate();
        this.addForm3 = {
          name: "",
          lineId: "",
          areaMode: 0,
          areaType: 1,
          areaContent: "",
          status: "",
        };
        this.addForm3.lineId = this.lineData.id;
        this.dialogVisible3 = true;
      }
      // // 如果下车点有数据
      // if (this.downData.length > 0) {
      //   // 0区域  1固定点
      //   if (this.downData[0].areaMode == "0") {
      //     this.areaModeList = [{ value: 0, label: "区域" }];
      //     this.locationType = 1;
      //     this.addForm3.areaMode = 0;
      //   } else {
      //     this.areaModeList = [{ value: 1, label: "固定点" }];
      //     this.locationType = 2;
      //     this.addForm3.areaMode = 1;
      //   }
      // } else {
      //   this.areaModeList = [
      //     { value: 0, label: "区域" },
      //     { value: 1, label: "固定点" },
      //   ];
      //   this.addForm3.areaMode = 0;
      // }
    },
    //新增途经点
    onAdd4 (title) {
      this.passPointTitle = title;
      this.isDisabled = title === "编辑";
      //0:车站 1:站点 2:区域
      queryIntercityStationList({
        // type: 2,
        channelNo: this.toConfigData.channelNo,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.stationList = res.data; //站点列表(只有区域)
        } else {
          this.$message.error(res.msg);
          this.stationList = [];
        }
      });
      // this.areaModeList = [{ value: 2, label: "区域" }];
      this.dialogVisiblePassPoint = true;
    },
    // 获取车次列表
    getDataList () {
      queryShiftList({ lineId: this.lineData.id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData2 = res.data;
          if(this.$refs['elTab']){
            this.$refs?.tableRef1?.$refs.Table.doLayout();
          }
        } else {
          this.$message.error(res.msg);
          this.tableData2 = [];
        }
        
        this.computeHeight();
      });
      queryAreaList({
        lineId: this.lineData.id,
        areaMode: null,
        areaType: 0,
        status: -1,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.upData = res.data;
        } else {
          this.$message.error(res.msg);
          this.upData = [];
        }
      });
      this.getDownList();
      //获取途经点列表
      queryIntercityLineStationRelationPage({
        lineId: this.lineData.id,
        lineStationType: 2, //0-上车点 1-下车点 2-途经点
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.passPointData = res.data?.list;
        } else {
          this.$message.error(res.msg);
          this.passPointData = [];
        }
      });
    },
    //下车点获取
    getDownList(){
      queryAreaList({
        lineId: this.lineData.id,
        areaMode: null,
        areaType: 1,
        status: -1,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.downData = res.data;
        } else {
          this.$message.error(res.msg);
          this.downData = [];
        }
      });
    },
    // ----------------------------------------------------------------
    // 上面线路表格渲染
    renderData () {
      linePageAPI(this.formData).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = [];
          this.tableData = res.data.list;
          this.total = res.data.total;
          res.data.list.map((item, index) => {
            if (item.status == 0) {
              this.statusArray[index] = true;
            } else {
              this.statusArray[index] = false;
            }
          });
          this.ticketPriceAll = res.data.list[0]?.ticketPrice;
          // this.handleNodeClick({ id: res.data.list[0].id });
        }
      });
    },
    // 起终点下拉数据获取
    selectdataAPIFun () {
      startStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.startSelect = [];
          res.data.map((item, index) => {
            this.startSelect.push({ value: index, label: item });
          });
        }
      });
      endStationAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.endSelect = [];
          res.data.map((item, index) => {
            this.endSelect.push({ value: index, label: item });
          });
        }
      });
    },
    // 企业回显
    getAddGroupId (v) {
      if (v == null) {
        this.formData.companyIds = [];
        this.addForm.companyId = "";
      } else {
        this.formData.companyIds = [v];
        this.addForm.companyId = v;
      }
    },
    getAddGroupName (val) {
      this.addForm.companyName = val;
    },
    //参营公司
    getAddGroupNameParticipate (val) {
      this.addForm.participateOperateCompanyNames = val?.split(",");
    },
    //参营公司回显
    getAddGroupIdParticipate (v) {
      this.addForm.participateOperateCompanyIds = v;
    },
    // 获取企业树
    getCompanyList () {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.formData.currentPage = page;
      this.renderData();
      // 默认选中第一行的样式
      const times = setTimeout(function () {
        document.querySelectorAll(".defaultTable table tbody tr")[0].click();
        clearTimeout(times);
      }, 400);
    },
    // 当前条数改变
    onSizeChange (size) {
      this.formData.pageSize = size;
      this.formData.currentPage = 1;
      this.renderData();
    },
    // 点击添加订单提醒号码
    addPhoneNumberFun () {
      const len = this.phoneNumberArray.length - 1;
      if (this.phoneNumberArray[len].trim() == "") {
        this.$message.error("请输入手机号后再进行添加");
      } else {
        this.phoneNumberArray.push("");
      }
    },
    // 点击去掉订单提醒号码
    reducePhoneNumberFun (index) {
      this.phoneNumberArray.splice(index, 1);
    },
    // 点击添加客服电话
    addcustomerFun () {
      const len = this.customerServicePhone.length - 1;
      if (this.customerServicePhone[len].trim() == "") {
        this.$message.error("请输入客服号码后再进行添加");
      } else {
        this.customerServicePhone.push("");
      }
    },
    // 点击去掉客服电话号码
    reducecustomerFun (index) {
      this.customerServicePhone.splice(index, 1);
    },
    // 手机号校验规则
    phoneRuleFun (rule, value, callback) {
      let reg = /(^1[3|4|5|7|8|9]\d{9}$)|(^09\d{8}$)/;
      if (this.phoneNumberArray.length == 1 && this.phoneNumberArray[0] == "") {
        callback("请输入订单提醒号码");
      } else {
        let on = true;
        for (let i = 0; i < this.phoneNumberArray.length; i++) {
          if (reg.test(this.phoneNumberArray[i]) == false) {
            callback("订单提醒号码格式错误");
            on = false;
            break;
          }
        }
        on && callback();
      }
    },

    // 客服电话校验规则
    customerPhoneFun (rule, value, callback) {
      let reg = /^(([0][1-9]{2,3}[0-9]{5,10})|(1(3|4|5|6|7|8|9)\d{9}))$/;
      if (
        this.customerServicePhone.length == 1 &&
        this.customerServicePhone[0] == ""
      ) {
        callback("请输入客服电话");
      } else {
        let con = true;
        for (let j = 0; j < this.customerServicePhone.length; j++) {
          if (reg.test(this.customerServicePhone[j]) == false) {
            callback("客服电话号码格式错误");
            con = false;
            break;
          }
        }
        con && callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  color: #c0c4cc;
}
.input-new-tag {
  width: 90px;
  margin-right: 10px;
  vertical-align: bottom;
}
.tips-out {
  position: relative;
}
.company-tips {
  position: absolute;
  left: -120px;
  bottom: -24px;
  color: #838585;
  line-height: 26px;
  font-size: 12px;
  width: 400px;
}
.is-realName {
  left: 0;
}
.calendarActive {
  background: #336ffe;
  color: #ffffff;
}

/deep/.shiftDatePicker {
  width: 160px !important;
}

/deep/.shift-select {
  width: 160px !important;
}

/deep/.shift-stage-date {
  width: 260px !important;
  margin-right: 16px;

  .el-range-separator {
    line-height: 24px !important;
  }
}

.shift-attribute-pop {
  width: calc(900px - 64px);
  padding: 15px 32px;

  .shift-box {
    .shiftA {
      margin-left: 10px;
      margin-top: 20px;

      .shiftA-box {
        border: 1px solid #dcdedf;
        border-radius: 4px;
        padding: 14px;
        margin-top: 20px;
        margin-left: 120px;

        .shift-calendar {
          width: 280px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #dcdedf;
          border-radius: 4px;
          padding: 15px 0px 0 15px;

          div {
            text-align: center;
            width: 24px;
            height: 20px;
            line-height: 20px;
            cursor: pointer;
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
          }
        }
      }
    }

    .shift-content {
      margin-top: 20px;
      margin-left: 10px;

      .shift-stage {
        padding: 20px;
        display: inline-block;
        width: calc(100% - 170px);
        border: 1px solid #dcdedf;
      }

      .shift-course-text {
        display: inline-block;
        margin-left: 14px;
        font-size: 14px;
        color: #ff8627;
      }
    }

    .shift-label {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
  }

  .shift-title {
    font-size: 14px;
    color: #333333;
    padding: 5px 20px;
    background: #f7f8f9;
    position: relative;
  }

  .shift-title:after {
    content: ' ';
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #336ffe;
  }
}

// ---------------------------------
/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 95px;
  height: 95px;
  line-height: 95px;
}

/deep/.hide .el-upload--picture-card {
  display: none;
}

/deep/ .el-upload--picture-card {
  width: 95px;
  height: 95px;
}

/deep/ .el-upload {
  width: 95px;
  height: 95px;
  line-height: 95px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 95px;
  height: 95px;
  line-height: 95px;
  transition: none !important;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 95px;
  height: 95px;
  line-height: 95px;
}

/deep/ .avatar {
  width: 95px;
  height: 95px;
}

.imgPopup {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;

  img {
    max-width: 70%;
    max-height: 70%;
  }
}

.seatPreview {
  color: #336ffe;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -22px;
}

/deep/.el-dropdown-link {
  cursor: pointer;
  color: #336ffe;
}

/deep/.el-icon-arrow-down {
  font-size: 12px;
}

/deep/.el-dialog__body {
  // height: 520px;
  overflow-y: auto;
  overflow-x: hidden;
}

/deep/.el-dialog__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 32px);
}

/deep/.el-dialog {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 50px;
  max-height: none;
}

/deep/.el-dialog__header {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 32px);
}

/deep/.el-upload--picture-card {
  width: 80px;
  height: 80px;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/deep/.el-form-item__label {
  line-height: 40px !important;
}

// .el-form-item {
//   margin-right: 28px !important;
// }
/deep/.addFormbody .el-input__inner {
  width: 184px !important;
}

/deep/.addFormbody .el-input__suffix {
  right: calc(100% - 180px) !important;
}
/deep/.dialog-form.el-form .el-form-item .el-input__suffix, .dialog-form.el-form .el-form-item .el-textarea .el-input__count{
  right: 5px !important;
}
/deep/.dialog-form.el-form .el-form-item .el-textarea__inner, .dialog-form.el-form .el-form-item .el-input__inner{  
    width: 100% !important;
 
}

.classify {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  position: relative;
  height: 18px;
  line-height: 18px;
}

.classify::after {
  content: ' ';
  position: absolute;
  width: 4px;
  height: 18px;
  top: 0;
  left: -12px;
  background: #336ffe;
}

.classify-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;
}

.addNumberBox {
  position: relative;
  .add {
    border: none;
    position: absolute;
    height: 24px;
    padding: 0 !important;
    top: 8px;
    left: 210px;    
    color: #336ffe;

    i {
      font-size: 12px;
      margin-right: 6px;
      font-weight: bold;
    }
  }

  .reduce {
    border: none;
    position: absolute;
    border-radius: 50%;
    height: 24px;
    padding: 0 !important;
    bottom: 8px;
    left: 210px;
    color: #336ffe;
  }
}

.textareaBox{
  width: 510px;
  .add{
    left: 530px;
  }
}

/deep/.el-tabs__content {
  height: calc(100% - 40px) !important;
}
/deep/.inner-input {
  .el-input__inner {
    width: 100px !important;
  }
}
.row-style {
  /deep/.el-form-item {
    margin-bottom: 0 !important;
  }
}

.lineManagement {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100vh - 132px);
  position: relative;

  .top-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .bottom-box {
    width: calc(100% - 40px);
    height: calc(100% - 32px);
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    padding: 16px 20px;
    background: #ffffff;

    .exit {
      width: 32px;
      height: 32px;
      color: #333333;
      font-size: 15px;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);

      i {
        cursor: pointer;
      }
    }

    .dataInfo {
      margin: 14px 0 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-content: flex-start;

      .dataInfo-box {
        width: 25%;
        height: 20px;
        line-height: 20px;
        margin-bottom: 16px;
      }
    }
    .entrust-content {
      margin-bottom: 8px;
      .entrust-head {
        background-color: #f7f8f9;
        border-left: 2px solid #336ffe;
        padding: 5px 0px 5px 21px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        .entrust-title {
          // font-size: 24px;
          // color: #000;
          // font-weight: 500;
          margin-right: 16px;
        }
      }
    }
  }

  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 10px;
    flex-flow: wrap;

    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }

    .actionBar-box:after {
      content: '';
      flex: auto;
    }

    .actionBar-box {
      width: calc(25% - 13px);
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;

      .col-1 {
        width: 84px;
        display: inline-block;
      }

      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);

        .el-select {
          width: 100%;
          max-width: 200px;
        }
      }
    }
  }

  .mapBody {
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .topForm {
      flex-shrink: 0;
      /deep/.el-form {
        display: flex;
        justify-content: flex-start;
        .el-input {
          width: 190px;
        }
        .el-form-item {
          margin-bottom: 18px;
        }
        .el-form-item__label {
          line-height: 10px !important;
        }
        .time {
          width: 100%;
          .el-input {
            width: 150px !important;
          }
          .el-input__inner {
            width: 150px;
          }
        }
      }
    }
    .bottomMap {
      flex: 1;
    }
    .dot-tips {
      font-size: 15px;
      margin-top: 10px;
      header {
        display: flex;
        align-items: center;
        .dot {
          width: 8px;
          height: 8px;
          border-radius: 100%;
          border: 3px solid #7ea1ff;
          background-color: #fff;
          margin: 0 10px;
        }
      }
      .tip {
        padding-left: 99px;
        color: red;
        margin-top: 5px;
      }
    }
  }
}
</style>

<template>
  <div class="operations">
    <div class="row" v-if="ruleType == '购票规则'">
      <div
        class="row-left"
        :style="{
          width: ['intercityUpdateUpDownPoint', 'area'].includes(
            operationData.regulationCode
          )
            ? '440px'
            : '200px',
        }"
      >
        {{
          operationData.unitType == 8
            ? operationData.regulationName + '（百分比值）'
            : operationData.regulationName
        }}
      </div>
      <div class="row-right">
        <el-input
          v-if="
            ['intercityUpdateUpDownPoint', 'sendDispatchOrderSms'].includes(
              operationData.regulationCode
            )
          "
          v-model="operationData.lineRegulationValue"
          clearable
          onkeyup="this.value = this.value.replace(/^0|[^\d]+/g,'');"
          maxlength="11"
        ></el-input>
        <el-input
          v-else-if="
            ['ticketServicePricesKey'].includes(operationData.regulationCode)
          "
          v-model="operationData.lineRegulationValue"
          clearable
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          maxlength="11"
        ></el-input>
        <el-input
          v-else
          v-model="operationData.lineRegulationValue"
          clearable
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
          maxlength="11"
          placeholder="不填写默认同步平台配置"
        ></el-input>
      </div>
    </div>
    <div v-if="ruleType == '退票规则'">
      <div class="row">
        <div class="row-left"><span>*</span>发车前时间区间起(含)</div>
        <div class="row-right">
          <el-input
            v-model="operationData.departureTimeStart"
            disabled
            type="number"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="row-left"><span>*</span>发车前时间区间止(不含)</div>
        <div class="row-right">
          <el-input
            v-model="operationData.departureTimeEnd"
            clearable
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            type="number"
            maxlength="5"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="row-left"><span>*</span>收取手续费比率(百分比)</div>
        <div class="row-right">
          <el-input
            v-model="operationData.chargeRate"
            clearable
            type="number"
            onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
            maxlength="3"
          ></el-input>
        </div>
      </div>
    </div>
    <div
      v-if="['改签规则', '检票规则', '司机端规则'].includes(ruleType)"
      class="row"
    >
      <div class="row-left">
        {{ operationData.regulationName }}
      </div>
      <div class="row-right">
        <el-input
          v-if="computedInput()"
          v-model.trim="operationData.lineRegulationValue"
          clearable
          onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
        ></el-input>
        <el-select
          v-if="computedSelect()"
          v-model="operationData.lineRegulationValue"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in downMenu"
            :key="item"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import {
  updateIntercityLineTicketRegulationAPI,
} from "@/api/lib/remouldAPI.js";
import { del } from 'vue';
export default {
  props: {
    operationData: {
      type: Object,
      default: () => { }
    },
    ruleType: {
      type: String,
      default: "退票规则"
    },
    lineId: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      value: null,
      downMenu: ["是", "否"],
      value:null,
    }
  },
  mounted () {
    if (['改签规则', '检票规则'].includes(this.ruleType)) {
      this.downMenu = this.operationData.unitType == 4 ? ["按固定值", "按百分比"] : ["是", "否"];
    }
  },
  methods: {
    handlekeyUp(){
      const reg=/(^[1-9]{1}[0-9]*$)/;//大于0的整数
      if(!reg.test(this.operationData.regulationValue)){
        this.operationData.regulationValue = null;//不满足边输入边替换掉不符合的
      }else{
        /**
         * todo
         */
      }      
    },
    computedInput () {
      const list = ['rebookFrequency', 'canRebookMinute', 'fixedValueRebook', 'percentageValueRebook'];
      const unitTypelist = ['SEE_PASSENGER_ORDERS'];
      return list.includes(this.operationData.regulationCode) || unitTypelist.includes(this.operationData.regulationType);
    },
    computedSelect () {
      const list = ['rebookWay', 'changedTicketIsRefund', 'canSpanDateRebook', 'canSpanLineRebook', 'DRIVER_END_OF_ITINERARY_PAYMENT', 'PASSENGER_CERTIFICATE_ENABLED_TYPE'];
      const unitTypelist = ['RELEASE_RESERVATION_TICKET', 'IS_RESERVED_TICKET'];
      return list.includes(this.operationData.regulationCode) || unitTypelist.includes(this.operationData.regulationType);
    },
    save () {
      /**
       * 如果没有id,表示线路规则新增；有id，表示修改，有id没有value表示删除使用平台默认
       */
      const { lineRegulationId, channelNo, type, regulationName, regulationCode, unitType, lineRegulationValue, regulationType } = this.operationData;
      let data = {
        id: lineRegulationId,
        lineId: this.lineId,
        regulationName,
        regulationValue: lineRegulationValue,
        channelNo,
        regulationCode
      };
      if (['购票规则', '改签规则', '检票规则'].includes(this.ruleType)) {
        data.type = type;
        data.unitType = unitType;
        if(['intercityUpdateUpDownPoint', 'sendDispatchOrderSms'].includes(regulationCode)){
          if(lineRegulationValue == '0'){
            data.regulationValue = null
          }
        }
      } else if (this.ruleType === '司机端规则') {
        delete data.regulationCode;
        data.regulationType = regulationType;
      } else if (this.ruleType === '退票规则') {
        const {
          departureTimeEnd: end,
          departureTimeStart: start,
          chargeRate: rate,
        } = this.operationData;
        if (!end || !rate) {
          this.$message.error("请填写必填项");
          return
        }
        if (end <= start) {
          this.$message.error(
            "'发车前时间区间止'需大于'发车前时间区间起'，请重新输入！"
          );
          return
        }
        if (rate > 100) {
          this.$message.error("手续费比率不得大于100！");
          return
        } else if (rate < 0) {
          this.$message.error("手续费比率不得小于0！");
          return
        }
        data = { ...this.operationData, lineId: this.lineId, };
      }
      this.$emit('updateRules', data);
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-input {
  width: 200px;
}
.row {
  width: 100%;
  height: 32px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  .row-left {
    width: 240px;
    height: 32px;
    text-align: right;
    line-height: 32px;
    padding-right: 14px;
    span {
      color: red;
    }
  }
  .row-right {
    width: calc(100% - 180px);
    height: 32px;
    line-height: 32px;
    span {
      display: inline-block;
      margin-right: 10px;
    }
    .content-2-input {
      margin-right: 10px;
      width: 80px;
    }
  }
}
</style>
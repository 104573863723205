<template>
  <div id="container" class="mymap"></div>
</template>

<script>
import AMap from "AMap";
export default {
  data() {
    return {
      map: {},
      rectangleEditor: {},
      rectangle: {},
      lineArr: [],
      editorColor: {
        1: {
          strokeColor: "#059370",
          fillColor: "#9ed09d",
        },
        2: {
          strokeColor: "#EA546D",
          fillColor: "#de7171",
        },
        3: {
          strokeColor: "#71076e",
          fillColor: "#b374b1",
        },
      },
      title: "",
    };
  },
  methods: {
    loadmap() {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 12,
      });
    },
    viewMask(row) {
      this.map.clearMap();
      this.selectObj = JSON.parse(row.areaContent);
      let self = this;
      let color = self.editorColor[1];
      let bounds = {};
      let selectObj = JSON.parse(row.areaContent);
      let southWest = new AMap.LngLat(selectObj[0].lng, selectObj[0].lat);
      let northEast = new AMap.LngLat(selectObj[2].lng, selectObj[2].lat);
      bounds = new AMap.Bounds(southWest, northEast);
      this.rectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: color.strokeColor,
        strokeOpacity: 0.5,
        strokeWeight: 6,
        fillColor: color.fillColor,
        fillOpacity: 0.5,
        strokeStyle: "dashed",
      });
      this.rectangle.setMap(this.map);
      this.map.setFitView([this.rectangle]);
    },
    clearMap() {
      this.map.clearMap();
      this.selectObj = null;
      this.rectangle = null;
    },
  },
  mounted() {
    this.loadmap(); // 加载地图和相关组件;
  },
};
</script>

<style>
.mymap {
  width: 100%;
  height: 63vh;
}
</style>

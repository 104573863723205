<template>
  <div class="line-rules">
    <div class="exit" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <!-- 关键信息展示 -->
    <div class="dataInfo">
      <div class="dataInfo-box">
        <label>线路名称：</label>
        <label>{{ lineRowData.name }}</label>
      </div>
      <div class="dataInfo-box">
        <label>所属企业：</label>
        <label>{{ lineRowData.companyName }}</label>
      </div>
      <div class="dataInfo-box">
        <label>所属渠道：</label>
        <label>{{ lineRowData.channelName }}</label>
      </div>
      <div v-show="listFind('可售渠道')" class="dataInfo-box">
        <label>可售渠道：</label>
        <label>
          {{
            lineRowData.vendibilityChannelName
              ? lineRowData.vendibilityChannelName.join(',')
              : '无'
          }}
        </label>
      </div>
      <div class="dataInfo-box">
        <label>购票类型：</label>
        <label>
          {{ lineRowData.isRealName === 0 ? '非实名' : '实名' }}
        </label>
      </div>
      <div class="dataInfo-box">
        <label>估计用时：</label>
        <label>{{ lineRowData.drivingTime }}</label>
      </div>
      <div class="dataInfo-box">
        <label>核定价：</label>
        <label>{{ lineRowData.ticketPrice }}</label>
      </div>
    </div>
    <!-- 提示 -->
    <div class="tips">
      <i class="el-icon-warning"></i>线路规则变更后次日生效
    </div>
    <!-- 底部规则 -->
    <div class="rule-tabs">
      <div class="rule-tabs-title">
        <div
          v-for="(item, index) in tabsList"
          :key="item.text"
          :class="item.class"
          @click="tabClick(index)"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="rule-tabs-content">
        <el-button
          v-if="operAtionIndex == 1"
          class="tabs-add"
          size="small"
          sort="primary"
          @click="addFun"
          >添加</el-button
        >
        <y-table
          v-if="tableData.length"
          :columns="titleName"
          :data="tableData"
          :col-class-type="true"
        >
          <span slot="action" slot-scope="{ row, $index }">
            <el-button
              v-if="operAtionIndex == 1 ? tableData.length == $index + 1 : true"
              type="text"
              size="small"
              sort="primary"
              @click="editFun(row)"
              >编辑</el-button
            >
            <el-button
              v-if="operAtionIndex == 1 && tableData.length == $index + 1"
              type="text"
              size="small"
              sort="primary"
              @click="deleteFun(row)"
              >删除</el-button
            >
          </span>
        </y-table>
        <p v-if="operAtionIndex == 1" class="refund-title">默认规则</p>
        <Table
          v-if="operAtionIndex == 1"
          :title-name="refundName"
          :table-data="tableDataRefund"
          :col-class-type="true"
        >
        </Table>
      </div>
    </div>
    <!-- 操作弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="600px"
      append-to-body
      @close="onDialogClose"
    >
      <rulesOperations
        ref="dialogVisibleRef"
        :operationData="operAtionData"
        @updateRules="confirmFun"
        :ruleType="tabsList[this.operAtionIndex].text"
        :lineId="lineRowData.id"
      ></rulesOperations>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="handleSure"
        >
          确认
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getIntercityLineTicketRegulationAPI,
  getIntercityLineRefundTicketRegulationAPI,
  getIntercityLineRebookRegulationAPI,
  getIntercityLineTicketCheckRegulationAPI,
  queryIntercityLineRebookRegulationAPI,
  updateIntercityLineTicketRegulationAPI,
  updateIntercityLineRebookRegulationAPI,
  updateIntercityLineTicketCheckRegulationAPI,
  updateIntercityLineDriverRegulationAPI,
  addRefundIntercityRegulationAPI,
  updateRefundIntercityRegulationAPI,
  deleteRefundIntercityRegulationAPI,
} from "@/api/lib/remouldAPI.js";
import rulesOperations from "./rulesOperations.vue"
export default {
  components: {
    rulesOperations,
  },
  props: {
    lineRowData: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {
      //如需要修改规则名称，需要把修改组件里面的名称对应修改，组件用的名称判断
      tabsList: [
        { class: "tabs tabs-active", text: "购票规则" },
        { class: "tabs", text: "退票规则" },
        { class: "tabs", text: "改签规则" },
        { class: "tabs", text: "检票规则" },
        { class: "tabs", text: "司机端规则" },
      ],
      operAtionIndex: 0,
      tableData: [],
      tableDataRefund: [],
      titleName: [],
      refundName: [
        {
          title: "发车前时间区间起（含）",
          props: "departureTimeStart",
        },
        {
          title: "发车前时间区间止（不含）",
          props: "departureTimeEnd",
        },
        {
          title: "收取手续费比率",
          props: "chargeRate",
          SpecialJudgment: res => {
            return res + "%";
          }
        },
      ],
      queryArgument: {},
      dialogVisible: false,
      operAtionData: {},
      title: "",
      editLoading: false,
    }
  },
  created () {
    this.queryArgument = {
      channelNo: this.lineRowData.channelNo,
      lineId: this.lineRowData.id
    };
  },
  mounted () {
    this.tabClick(0);
  },
  methods: {
    // 删除按钮点击
    deleteFun (val) {
      deleteRefundIntercityRegulationAPI(val.id).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderDataB();
        }
      });
    },
    confirmFun (val) {
      this.editLoading = true;
      switch (this.operAtionIndex) {
        case 0:
          updateIntercityLineTicketRegulationAPI(val).then(res => {
            if (res.code === "SUCCESS") {
              this.onDialogClose();
              this.renderDataA();
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          });
          break;
        case 1:
          const data = {
            ...val,
            channelNo: this.lineRowData.channelNo
          }
          if (this.title == '新增') {
            addRefundIntercityRegulationAPI(data).then(res => {
              if (res.code == "SUCCESS") {
                this.onDialogClose();
                this.renderDataB();
              }
              this.editLoading = false;
            }).catch(err => {
              this.editLoading = false;
            })
          } else {
            updateRefundIntercityRegulationAPI(data).then(res => {
              if (res.code == "SUCCESS") {
                this.onDialogClose();
                this.renderDataB();
              }
              this.editLoading = false;
            }).catch(err => {
              this.editLoading = false;
            })
          }
          break;
        case 2:
          updateIntercityLineRebookRegulationAPI(val).then(res => {
            if (res.code === "SUCCESS") {
              this.onDialogClose();
              this.renderDataD();
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          })
          break;
        case 3:
          updateIntercityLineTicketCheckRegulationAPI(val).then(res => {
            if (res.code === "SUCCESS") {
              this.onDialogClose();
              this.renderDataJ();
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          })
          break;
        case 4:
          updateIntercityLineDriverRegulationAPI(val).then(res => {
            if (res.code === "SUCCESS") {
              this.onDialogClose();
              this.renderDataG();
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          })
          break;
      }
    },
    handleSure () {
      this.$refs.dialogVisibleRef.save();
    },
    onDialogClose () {
      this.dialogVisible = false;
    },
    editFun (row) {
      this.title = "编辑";
      this.operAtionData = this.deepClone(row);
      this.dialogVisible = true;
    },
    addFun () {
      this.title = "新增";
      if (this.tableData.length == 0) {
        this.operAtionData = {
          departureTimeStart: 0,
        };
      } else {
        this.operAtionData = {
          departureTimeStart:
            this.tableData[this.tableData.length - 1].departureTimeEnd,
        };
      }
      this.dialogVisible = true;
    },
    renderDataA () {
      getIntercityLineTicketRegulationAPI(this.queryArgument).then(res => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      })
    },
    renderDataB () {
      getIntercityLineRefundTicketRegulationAPI(this.queryArgument).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.channelLineRefundTicketRegulations;
          this.tableDataRefund = res.data.channelRefundTicketRegulations;
        }
      });
    },
    // 改签规则数据获取
    renderDataD () {
      getIntercityLineRebookRegulationAPI(this.queryArgument).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data;
          }
        }
      );
    },
    //检票规则获取
    renderDataJ () {
      getIntercityLineTicketCheckRegulationAPI(this.queryArgument).then(res => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      })
    },
    //司机端规则
    renderDataG () {
      queryIntercityLineRebookRegulationAPI(this.queryArgument).then(res => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      })
    },
    returnList () {
      this.$emit('closeRulesShow');
    },
    // 点击tab标签
    tabClick (index) {
      this.tableData = [];
      this.operAtionIndex = index;
      this.tabsList.map((item) => {
        item.class = "tabs";
      });
      this.tabsList[index].class = "tabs tabs-active";
      switch (index) {
        case 0:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "默认配置",
              prop: "regulationValue",
            },
            {
              label: "线路配置",
              prop: "lineRegulationValue",
              customRender: res => {
                return res ? res : '-'
              }
            },
            {
              label: "单位",
              prop: "unitType",
              customRender: (res) => {
                let unitTypeList = ["天", "分钟", "元/人", "-", "人", "-", "平方米", "-", "百分比"];
                return unitTypeList[res]
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: 70,
            },
          ];
          this.renderDataA();
          break;
        case 1:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: "50",
            },
            {
              label: "发车前时间区间起（含）",
              prop: "departureTimeStart",
            },
            {
              label: "发车前时间区间止（不含）",
              prop: "departureTimeEnd",
            },
            {
              label: "收取手续费比率",
              prop: "chargeRate",
              customRender: (res) => {
                return res + "%";
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: 110,
            },
          ];
          this.renderDataB();
          break;
        case 2:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "默认配置",
              prop: "regulationValue",
            },
            {
              label: "线路配置",
              prop: "lineRegulationValue",
              customRender: res => {
                return res ? res : '-'
              }
            },
            {
              label: "单位",
              prop: "unitType",
              customRender: (res) => {
                let unitTypeList = ["-", "次", "分钟", "元", "字符值", "是/否", "百分比"];
                return unitTypeList[res];
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: "70",
            },
          ];
          this.renderDataD();
          break;
        case 3:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "默认配置",
              prop: "regulationValue",
            },
            {
              label: "线路配置",
              prop: "lineRegulationValue",
              customRender: res => {
                return res ? res : '-'
              }
            },
            {
              label: "单位",
              prop: "unitType",
              customRender: (res) => {
                let unitTypeList = ["-", "次", "分钟", "元", "字符值", "是/否", "百分比"];
                return unitTypeList[res];
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: "70",
            },
          ];
          this.renderDataJ();
          break;
        case 4:
          this.titleName = [
            {
              label: "序号",
              type: "index",
              width: 50,
            },
            {
              label: "规则名",
              prop: "regulationName",
            },
            {
              label: "默认配置",
              prop: "regulationValue",
            },
            {
              label: "线路配置",
              prop: "lineRegulationValue",
              customRender: res => {
                return res ? res : '-'
              }
            },
            {
              label: "单位",
              prop: "regulationType",
              customRender: (res) => {
                return res == "SEE_PASSENGER_ORDERS" ? "天" : "是/否";
              },
            },
            {
              label: "操作",
              scopedSlots: "action",
              width: "70",
            },
          ];
          this.renderDataG();
          break;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.tips {
  color: #666666;
  font-size: 14px;
  background-color: #e4ecff;
  border-radius: 6px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin: -16px 0 8px;
}
.el-icon-warning {
  color: #336ffe;
  font-size: 16px;
  margin-right: 6px;
}
/deep/.el-table:not(.no-blank) .el-table__empty-block .el-table__empty-text {
  height: 240px;
}
.refund-title {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin: 16px 0;
}
.line-rules {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 16px 20px;
  background: #ffffff;
  .exit {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
    i {
      cursor: pointer;
    }
  }
  .dataInfo {
    margin: 14px 0;
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;

    .dataInfo-box {
      width: 25%;
      height: 20px;
      line-height: 20px;
      margin-bottom: 24px;
    }
  }
  .rule-tabs {
    width: 100%;
    border: 1px solid #dcdfe6;
    height: calc(100% - 180px);
    .rule-tabs-title {
      height: 40px;
      background: #f5f7fa;
      border-bottom: 1px solid #dcdfe6;
      .tabs {
        display: inline-block;
        height: 100%;
        padding: 0 20px;
        cursor: pointer;
        color: #909399;
        font-size: 14px;
        line-height: 40px;
        margin-left: -1px;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
      .tabs-active {
        border-bottom: 1px solid #ffffff;
        color: #409eff;
        background-color: #fff;
        border-right: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
      }
    }
    .rule-tabs-content {
      padding: 15px;
      background: #ffffff;
      .tabs-add {
        background: #336fee;
        color: #ffffff;
        margin-bottom: 10px;
      }
    }
  }
}
</style>